import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7e28d46e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-view p-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, {
      class: "login-card col-12 col-sm-10 col-md-5 col-xl-4",
      shadow: "hover"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ]))
}