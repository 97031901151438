<template>
    <el-header class="h2">
        Listando Questionarios
    </el-header>
    <el-card shadow="always" header="Questionarios" footer="">
        <el-skeleton :loading="loading" animated :rows="7" class="col-12">
            <template #default>
                <el-row class="row justify-content-end m-0 p-0 py-3" justify="end">
                    <div class="col-12 col-md-6 col-lg-auto">
                        <el-date-picker size="large" :editable="false" format="YYYY/MM/DD" v-model="date" placeholder="selecionar data"/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-auto">
                        <el-input type="text" v-model="search" size="large" placeholder="Procurar título ou clube..." class="col-12" />
                    </div>
                </el-row>
                <el-table :data="showData" stripe class="col-12">
                    <el-table-column type="index" />
                    <el-table-column show-overflow-tooltip :min-width="200" prop="team.name">
                        <template #header>
                            <label class="col-12 pointer" @click="() => setSortProps('team.name')">
                                Clube&emsp;
                                <el-icon>
                                    <CaretTop v-if="sortProps.prop == 'team.name' && sortProps.order == 'asc'" />
                                    <CaretBottom v-else-if="sortProps.prop == 'team.name' && sortProps.order == 'desc'" />
                                    <DCaret v-else/>
                                </el-icon>
                            </label> 
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" :min-width="200">
                        <template #header>
                            <label class="col-12 pointer" @click="() => setSortProps('title')">
                                T&iacute;tulo&emsp;
                                <el-icon>
                                    <CaretTop v-if="sortProps.prop == 'title' && sortProps.order == 'asc'" />
                                    <CaretBottom v-else-if="sortProps.prop == 'title' && sortProps.order == 'desc'" />
                                    <DCaret v-else/>
                                </el-icon>
                            </label> 
                        </template>
                    </el-table-column>
                    <el-table-column prop="day" :min-width="200">
                        <template #header>
                            <label class="col-12 pointer" @click="() => setSortProps('day')">
                                Data associada&emsp;
                                <el-icon>
                                    <CaretTop v-if="sortProps.prop == 'day' && sortProps.order == 'asc'" />
                                    <CaretBottom v-else-if="sortProps.prop == 'day' && sortProps.order == 'desc'" />
                                    <DCaret v-else/>
                                </el-icon>
                            </label> 
                        </template>
                    </el-table-column>
                    <el-table-column label="Status" :filter-multiple="false"
                        :min-width="110"
                        :filters="[
                            { value: '1', text: 'Pendente' },
                            { value: '2', text: 'Disponivel' },
                            { value: '3', text: 'Fechado' },
                        ]"
                        :filter-method="filterStatus"
                    >
                        <template #default="data">
                            <el-tag :type="new Date(data.row.from) > new Date() ? 'info' : (new Date(data.row.to) > new Date() ? 'success':'warning')">
                                {{ new Date(data.row.from) > new Date() ? 'Pendente' : (new Date(data.row.to) > new Date() ? 'Disponivel':'Fechado') }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="Ações" :min-width="200">
                        <template #default="data">
                            <el-tooltip content="Copiar url">
                                <el-button icon="CopyDocument" plain @click="copy(data.row.permalink)" />
                            </el-tooltip>
                            <el-button plain type="primary" icon="View" @click="navigate('/quizzs/' + data.row.id)">
                            </el-button>
                            <el-button plain type="danger" @click="deleteQuizz(data.row.id)" icon="Delete">
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="py-4" @change="changePage" :default-current-page="1" hide-on-single-page :page-count="pages" :total="pages">
                </el-pagination>
            </template>
        </el-skeleton>
    </el-card>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
    import { useRouter } from 'vue-router'
    import Api from '@/services/api'
    import { Quizz } from '@/services/types'
    import { ElMessage, ElMessageBox } from 'element-plus'
    import { useStore } from 'vuex'

    export default defineComponent({
        setup() {
            document.title = "Questionarios"

            const store = useStore()
            const router = useRouter()
            const navigate = (url: string) => { router.push(url) }
            const loading = ref<boolean>(true)
            const search = ref<string>('')
            const page = ref<number>(1)
            const date = ref<Date>()
            const limit = 10

            const quizzes = reactive<Array<Quizz>>([]);
            
            
            const fetchQuizzes = async () => {
                const response = await Api.get('/quizz')
                if (response.status >= 200 && response.status < 300) {
                    response.data.quizzs.forEach((quizz: any, i: number) => {
                        quizz.day = new Date(quizz.day).toLocaleDateString("af")
                        quizzes[i] = quizz
                    })
                    loading.value = false
                } else {
                    ElMessage.error({message: "Ocorreu um erro ao buscar os dados"})
                }
            }
            onMounted(async () => {
                loading.value = true
                await fetchQuizzes()
                loading.value = false
            })

            const filteredData = computed(() =>
                quizzes.filter((quizz: any) => 
                    (!search.value ||
                    quizz.title.toLowerCase().includes(search.value.toLowerCase()) ||
                    quizz.team.name.toLowerCase().includes(search.value.toLowerCase())) &&
                    (!date.value || quizz.day == date.value.toLocaleDateString())
                )
            )

            const pages = computed(() =>
                Math.floor(filteredData.value.length / limit) +
                ((filteredData.value.length % limit > 0 && filteredData.value.length > limit) ? 1 : 0)
            )

            const sortProps = reactive<{
                prop: string | null
                order: 'asc' | 'desc' | null
            }>({
                prop: null,
                order: null
            })

            const setSortProps = (prop: string) => {
                if (sortProps.prop == prop) {
                    if (sortProps.order == 'asc') { sortProps.order = 'desc'; return; }
                    else if (sortProps.order == 'desc') { sortProps.order = null; return; }
                }
                sortProps.prop = prop
                sortProps.order = 'asc'
            }

            const sortedData = computed(() => {
                const prop = sortProps.prop
                const order = sortProps.order
                if (prop && order) {
                    return filteredData.value.slice(0).sort((x: any, y: any) => {
                        if (prop == 'team.name') {
                            x[prop] = x.team.name
                            y[prop] = y.team.name
                        }
                        if (x[prop].toLowerCase() < y[prop].toLowerCase()) { return order == 'asc' ? 1:-1 }
                        if (x[prop].toLowerCase() > y[prop].toLowerCase()) { return order == 'desc' ? 1:-1 }
                        return 0
                    })
                }
                return filteredData.value.slice(0)
            })

            const showData = computed(() => {
                const first = (page.value - 1) * limit
                return sortedData.value?.slice(first, first + limit)
            })

            const deleteQuizz = (id: number) => {
                ElMessageBox.confirm("Deseja realmente eliminar?", 'Aviso', {
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonClass: 'btn-danger',
                    type: 'warning',
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = "Eliminando"
                            const del = await Api.delete('/quizz/' + id)
                            if (del.status >= 200 && del.status < 300) {
                                ElMessage.success({message: "Deletado com sucesso"})
                                quizzes.forEach((quizz, i) => {
                                    if (quizz.id == id) {
                                        quizzes.splice(i, 1)
                                    }
                                })
                                done()
                            } else {
                                ElMessage.error({message: "Ocorreu um erro ao tentar deletar"})
                            }
                            instance.showCancelButton = false
                        } else {
                            done()
                        }
                    }
                }).then((() => {
                    //
                })).catch(() => {
                    // nothing
                })
            }

            const changePage = (n: number) => { page.value = n }
            const filterStatus = ((val: string, row: any) => {
                const now = new Date()
                if (val == '1') { return (new Date(row.from)) > now }
                else if (val == '2') { return (new Date(row.from)) <= now && (new Date(row.to)) >= now }
                else if (val == '3') { return (new Date(row.to)) < now }
            })

            navigator
            return {
                navigate,
                loading,
                search,
                page,
                pages,
                showData,
                date,
                setSortProps,
                sortProps,
                deleteQuizz,
                changePage,
                filterStatus,
                navigator,
                copy: async (text: string) => {
                    await navigator.clipboard.writeText(`${store.getters.baseRUrl}${text}`)
                    ElMessage.success("Copiado para a área de transferência")
                }
            }
        },
    })
</script>

