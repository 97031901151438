import { createStore } from 'vuex';

interface State {
    loading: boolean
    route: string
    baseRUrl: string
}

const store = createStore<State>({
    state: {
        loading: false,
        route: '/home',
        baseRUrl: 'https://app-carga.planejadordetreino.com/r/'
    },
    mutations: {
        setLoading(state, loading: boolean) {
            state.loading = loading;
        },
        setRoute(state, route: string) {
            state.route = route
        }
    },
    actions: {
        login({ commit }, token: string) {
            commit
            sessionStorage.setItem("token", token)
        },
        logout() {
            sessionStorage.removeItem("token")
        },
        setLoading({ commit }, loading: boolean) {
            commit('setLoading', loading)
        },
        navigate({ commit }, route: string) {
            commit('setRoute', route)
        }
    },
    getters: {
        isLoggedIn: () => sessionStorage.getItem("token") != null,
        loading: state => state.loading,
        route: state => state.route,
        baseRUrl: state => state.baseRUrl
    }
});

export default store;
