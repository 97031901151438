<template>
    <el-form
    ref="ruleFormRef"
    :rules="rules"
    :model="user"
    class="login-form" label-position="top" size="large">
        <el-form-item label="Insira seu nome" prop="name">
            <el-input v-model="user.name" placeholder="Nome" prefix-icon="User">
            </el-input>
        </el-form-item>   
        <el-form-item label="Insira seu email" prop="email">
            <el-input v-model="user.email" placeholder="Email" prefix-icon="Message">
            </el-input>
        </el-form-item>
        <el-form-item label="Insira sua senha" prop="password">
            <el-input v-model="user.password" type="password" placeholder="Senha" prefix-icon="Lock" show-password>
            </el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary"
                class="login-button col-12 col-md-auto m-1"
                @click="handleSignup(ruleFormRef)"
                :loading="loading"
            >
                Criar Conta
            </el-button>
            <el-button :disabled="loading" plain class="create-account-button col-12 col-md-auto m-1" tag="router-link" to="/login">Ja tenho uma conta</el-button>
        </el-form-item>
        <el-form-item class="forgot-password-link">
            <router-link to="/forgot-password">Esqueci a minha senha</router-link>
        </el-form-item>
    </el-form>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'
import Api from '@/services/api';
import { User } from '@/services/types'


export default defineComponent({
    name: 'SignupView',
    setup() {
        const router = useRouter();
        const store = useStore()
        
        const loading = ref<boolean>(false)

        const ruleFormRef = ref<FormInstance>()
        const user = reactive<User>({
            name: '',
            email: '',
            password: ''
        })
        const rules = reactive<FormRules<User>>({
            name: [
                { required: true, message: 'Por favor insira o nome', trigger: 'blur' },
                { min: 2, message: 'O nome deve ter pelo menos 2 caracteres', trigger: 'blur' },
            ],
            email: [
                { required: true, message: 'Por favor insira o seu email', trigger: 'blur'},
                {  type: 'email', message: 'Por favor insira um email valido', trigger: 'blur'},
            ],
            password: [
                { required: true, message: 'Por favor digite uma senha', trigger: 'blur' },
                { min: 6, message: 'A senha deve ter pelo menos 6 caracteres', trigger: 'blur'},
            ],
        })

        const handleSignup = async (formEl: FormInstance | undefined) => {
            if (!formEl) { return }
            await formEl.validate(async (valid) => {
                if (valid) {
                    loading.value = true
                    const res = await Api.post("/auth/signup", {
                        name: user.name,
                        email: user.email,
                        password: user.password
                    })
                    if (res.status >= 200 && res.status < 300) {
                        store.dispatch('login', res.data.token)
                        console.log("entrando")
                        router.push("/home")
                    } else if (res.status == 422) {
                        if (res.data.errors) {
                            const erros = res.data.errors
                            ruleFormRef.value?.fields.forEach((val) => {
                                Object.keys(erros).forEach(field => {
                                    if (val.prop == field) {
                                        val.validateState = "error"
                                        ElMessage({
                                            type: "error",
                                            message: res.data.errors[field][0]
                                        })
                                    }
                                })
                            })
                        }
                    }
                    loading.value = false
                } else {
                    ElMessage.error({
                        message: "Por favor preencha corretamente"
                    })
                }
            })
        }

        return {
            handleSignup,
            ruleFormRef,
            loading,
            rules,
            user
        };
    }
});
</script>
