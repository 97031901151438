import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import LoginLayout from '@/views/auth/LoginLayout.vue';
import LoginView from '@/views/auth/LoginView.vue';
import SignupView from '@/views/auth/SignupView.vue';
import AppLayout from '@/views/app/AppLayout.vue';
import HomeView from '@/views/app/HomeView.vue';
import TeamsView from '@/views/app/TeamsView.vue';
import PlayersView from '@/views/app/PlayersView.vue';
import QuizzesView from '@/views/app/QuizzesView.vue';
import NewTeamView from '@/views/app/NewTeamView.vue';
import TeamView from '@/views/app/TeamView.vue';
import NewPlayerView from '@/views/app/NewPlayerView.vue';
import NewQuizzView from '@/views/app/NewQuizzView.vue';
import ViewQuizzView from '@/views/app/QuizzView.vue';
import AnswersView from '@/views/app/AnswersView.vue';
import QuizzView from '@/views/QuizzView.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Login',
        redirect: '/login',
        component: LoginLayout,
        children: [
            {
                path: '/login',
                name: 'LoginRoute',
                component: LoginView
            },
            {
                path: '/signup',
                name: 'SignupRoute',
                component: SignupView
            }
        ]
    },
    {
        name: 'App',
        path: '/home',
        component: AppLayout,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/home',
                name: 'Home',
                component: HomeView
            },
            {
                path: '/teams',
                name: 'Teams',
                component: TeamsView
            },
            {
                path: '/quizzs',
                name: 'Quizzs',
                component: QuizzesView
            },
            {
                path: '/players/:id',
                name: 'Players',
                component: PlayersView
            },
            {
                path: '/new-team',
                name: 'NewTeam',
                component: NewTeamView
            },
            {
                path: '/teams/:id',
                name: 'Team',
                component: TeamView
            },
            {
                path: '/new-player',
                name: 'NewPlayer',
                component: NewPlayerView
            },
            {
                path: '/new-quizz',
                name: 'NewQuizz',
                component: NewQuizzView
            },
            {
                path: '/quizzs/:id',
                name: 'ViewQuizz',
                component: ViewQuizzView
            },
            {
                path: '/answers/:id',
                name: 'AnswersView',
                component: AnswersView
            },
        ]
    },
    {
        path: '/r/:permalink',
        name: 'FormQuizzView',
        component: QuizzView
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isLoggedIn = store.getters.isLoggedIn;

    if (requiresAuth && !isLoggedIn) {
        next({ name: 'Login' }); 
    } else {
        next();
    }
});

export default router;
