<template>
    <el-container class="app-layout">
        <!-- Navbar -->
        <el-header class="app-header p-0" height="1">
            <el-row justify="space-between" align="middle" class="p-2 col-12">
                <el-col class="col-auto">
                    <el-button
                        icon="Menu"
                        @click="toggleSidebar" 
                        class="menu-toggle-button"
                    ></el-button>
                    <span class="app-title">Nome da app</span>
                </el-col>
                <el-row align="middle" :gutter="15">
                    <el-col class="d-none d-sm-inline col-auto">
                        <el-tooltip
                            effect="light"
                            content="Modo tela cheia"
                            placement="bottom"
                        >
                            <el-icon role="button" @click="toggleFullscreen">
                                <Rank v-if="isFullscreen"/>
                                <FullScreen v-else />
                            </el-icon>
                        </el-tooltip>
                    </el-col>
                    <el-col class="col-auto">
                            <el-tooltip
                                effect="light"
                                content="Terminar sesssao"
                                placement="bottom"
                            >
                                <el-icon shape="circle" role="button" @click="logout">
                                    <SwitchButton />
                                </el-icon>
                            </el-tooltip>
                    </el-col>
                </el-row>
            </el-row>
        </el-header>

        <!-- Sidebar and Main Content -->
        <el-container>
            <el-aside class="app-sidebar col-auto">
                <el-menu 
                    :collapse="!isSidebarOpen"
                    :default-active="activeMenu" 
                    active-text-color="#ffd04b"
                    background-color="#545c64"
                    class="el-menu-vertical-demo" 
                    router 
                    unique-opened
                    text-color="#fff"
                >
                    <el-menu-item index="/profile" title="Profile">
                        <el-icon><UserFilled /></el-icon>
                        <span>Perfil</span>
                    </el-menu-item>
                    <el-menu-item index="/home" title="Home">
                        <el-icon><House /></el-icon>
                        <span>DashBoard</span>
                    </el-menu-item>
                    <el-sub-menu title="Clubes" index="1">
                        <template #title>
                            <el-icon><Football /></el-icon>
                            <span>Meus clubes</span>
                        </template>
                        <el-menu-item index="/teams" title="Clubes">
                            <el-icon><View /></el-icon>
                            <span >Listar clubes</span>
                        </el-menu-item>
                        <el-menu-item index="/new-team" title="Clubes">
                            <el-icon><CirclePlusFilled /></el-icon>
                            <span >Adicionar clube</span>
                        </el-menu-item>
                    </el-sub-menu>
                    <el-menu-item index="/new-player" title="adicionar Jogadores">
                        <el-icon><Plus /></el-icon>
                        <span >Adicionar jogador</span>
                    </el-menu-item>
                    <el-sub-menu title="Questionário" index="2">
                        <template #title>
                            <el-icon><InfoFilled /></el-icon>
                            <span >Questionários</span>
                        </template>
                        <el-menu-item index="/quizzs">
                            <el-icon><View /></el-icon>
                            <span >Listar Questionários</span>
                        </el-menu-item>
                        <el-menu-item index="/new-quizz">
                            <el-icon><CirclePlusFilled /></el-icon>
                            <span >Adicionar Questionário</span>
                        </el-menu-item>
                    </el-sub-menu>
                    <el-menu-item index="/payment" title="Pagamentos">
                        <el-icon><Coin /></el-icon>
                        <span >Activação da conta</span>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-main class="">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useFullscreen, useDark, useToggle } from '@vueuse/core'
import Api from '@/services/api';

export default defineComponent({
    name: 'AppLayout',
    setup() {
        const store = useStore();
        const router = useRouter();
        
        const isSidebarOpen = ref(false);
        const activeMenu = ref<string>(store.getters.route)

        router.beforeEach(route => {
            activeMenu.value = route.path
        })

        const toggleSidebar = () => {
            isSidebarOpen.value = !isSidebarOpen.value;
        };

        const handleLogout = () => {
            store.dispatch('logout').then(() => {
                router.push({ name: 'Login' });
            });
        };

        const { isFullscreen, toggle: toggleFullscreen } = useFullscreen();
        const isDark = useDark();
        const toggleDark = () => {
            useToggle(isDark)()
        }

        const logout = async () => {
            Api.post("/logout", {})
            store.dispatch('logout')
            router.push("/")
        }

        return {
            isSidebarOpen,
            activeMenu,
            toggleSidebar,
            handleLogout,
            toggleFullscreen,
            isFullscreen,
            isDark,
            toggleDark,
            logout
        };
    }
});
</script>

<style scoped lang="scss">
.app-layout {
    height: 100vh;

    .app-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        background-color: #409EFF;
        color: white;
        
        .app-title {
            font-size: 1.2em;
        }

        .menu-toggle-button {
            margin-right: 10px;
        }
    }

    .app-sidebar {
        transition: all .3s;
    
        .el-menu-vertical-demo {
            height: 100%;
            &:not(.el-menu--collapse) {
                width: 240px;
            }
        }
    }
}




</style>
