<template>
    <el-form
        ref="loginForm"
        :rules="rules"
        :model="user"
        class="login-form" label-position="top" size="large"
    >
        <el-header class="text-center h2 py-3">
            Iniciar sessão
        </el-header>
        <el-form-item label="Insira seu email" prop="email">
            <el-input v-model="user.email" placeholder="Email" prefix-icon="Message">
            </el-input>
        </el-form-item>
        <el-form-item label="Insira sua senha" prop="password">
            <el-input v-model="user.password" type="password" placeholder="Senha" prefix-icon="Lock" show-password>
            </el-input>
        </el-form-item>
        <el-form-item>
            <el-button 
                type="primary"
                class="login-button col-12 col-md-auto m-1"
                @click="handleLogin(loginForm)"
                :loading="loading"
            >Entrar</el-button>
            <el-button :disabled="loading" plain class="create-account-button col-12 col-md-auto m-1" tag="router-link" to="/signup">Criar Conta</el-button>
        </el-form-item>
        <el-form-item class="forgot-password-link">
            <router-link to="/forgot-password">Esqueci a minha senha</router-link>
        </el-form-item>
    </el-form>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import type { FormInstance, FormRules } from 'element-plus'
import Api from '@/services/api'
import { User } from '@/services/types'

export default defineComponent({
    name: 'LoginView',
    setup() {
        const store = useStore();

        const loading = ref<boolean>(false)

        const loginForm = ref<FormInstance>()
        const rules = reactive<FormRules<User>>({
            email: [
                { required: true, message: 'Por favor insira o seu email', trigger: 'blur'},
            ],
            password: [
                { required: true, message: 'Por favor digite a senha', trigger: 'blur'},
            ],
        })
        const user = reactive<User>({
            email: '',
            password: '',
        })
        const handleLogin = async (formEl: FormInstance | undefined) => {
            if (!formEl) { return }
            await formEl.validate(async (valid) => {
                if (valid) {
                    loading.value = true
                    const res = await Api.post("/auth/login", {
                        email: user.email,
                        password: user.password
                    })
                    if (res.status >= 200 && res.status < 300) {
                        store.dispatch('login', res.data.token)
                        window.location.href = "/home"
                    } else if (res.status == 422) {
                        if (res.data.errors) {
                            const erros = res.data.errors
                            loginForm.value?.fields.forEach((val) => {
                                Object.keys(erros).forEach(field => {
                                    if (val.prop == field) {
                                        val.validateState = "error"
                                        ElMessage({
                                            type: "error",
                                            message: res.data.errors[field][0]
                                        })
                                    }
                                })
                            })
                        }
                    }
                    loading.value = false
                } else {
                    ElMessage.error({
                        message: "Por favor preencha corretamente"
                    })
                }
            })
        }

        return {
            handleLogin,
            user,
            loginForm,
            rules,
            loading
        };
    }
});
</script>
