<template>
    <el-header v-if="quizz.answers">
        <span class="h2 d-inline p-1">{{ quizz.title }}</span>
        <span class="p-1 d-inline small">{{ quizz.day }}</span>
    </el-header>
    <el-card shadow="always" :header="quizz.team ? quizz.team.name + ' - ' + quizz.team.category : 'Respostas'" footer="">
        <el-skeleton :loading="loading" animated :rows="8" class="col-12">
            <template #default>
                <el-row :gutter="10">
                    <el-col class="col-12 h6">Mostrar</el-col>
                    <el-col :sm="12" :md="8" :lg="6" v-for="prop in tableProps" :key="prop.name">
                        <el-checkbox :name="prop.name" :checked="prop.show" v-model="prop.show" :label="prop.label"/>
                    </el-col>
                </el-row>
                <el-row class="py-3" justify="end">
                    <el-input type="text" v-model="search" size="large" placeholder="Procurar jogador..." class="col-auto" />
                </el-row>
                <el-table :data="showData" stripe border class="col-12">
                    <el-table-column show-overflow-tooltip prop="name" :min-width="200">
                        <template #default="data">
                            {{ data.row.player.name }}
                        </template>
                        <template #header>
                            <label class="col-12 pointer" @click="() => setSortProps('name')">
                                Nome do Jogador&emsp;
                                <el-icon>
                                    <CaretTop v-if="sortProps.prop == 'name' && sortProps.order == 'asc'" />
                                    <CaretBottom v-else-if="sortProps.prop == 'name' && sortProps.order == 'desc'" />
                                    <DCaret v-else/>
                                </el-icon>
                            </label> 
                        </template>
                    </el-table-column>

                    <template v-for="prop in tableProps" :key="prop.name">
                        <el-table-column v-if="prop.show" show-overflow-tooltip :min-width="200" :prop="prop.name">
                            <template #header>
                                <label class="col-12 pointer" @click="() => setSortProps(prop.name)">
                                    {{ prop.label }}&emsp;
                                    <el-icon>
                                        <CaretTop v-if="sortProps.prop == prop.name && sortProps.order == 'asc'" />
                                        <CaretBottom v-else-if="sortProps.prop == prop.name && sortProps.order == 'desc'" />
                                        <DCaret v-else/>
                                    </el-icon>
                                </label> 
                            </template>
                        </el-table-column>
                    </template>

                    <el-table-column show-overflow-tooltip :min-width="200" v-if="tableProps[1].show">
                        <template #default="data">
                            {{ data.row.pain?.join(', ') }}
                        </template>
                        <template #header>
                            <label class="col-12">
                                Locais de dor&emsp;
                            </label> 
                        </template>
                    </el-table-column>

                    <el-table-column :width="200">
                        <template #default="data">
                            {{ new Date(data.row.created_at).toLocaleString('af') }}
                        </template>
                        <template #header>
                            <label class="col-12 pointer" @click="() => setSortProps('created_at')">
                                Respondeu em
                                <el-icon>
                                    <CaretTop v-if="sortProps.prop == 'created_at' && sortProps.order == 'asc'" />
                                    <CaretBottom v-else-if="sortProps.prop == 'created_at' && sortProps.order == 'desc'" />
                                    <DCaret v-else/>
                                </el-icon>
                            </label> 
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="py-4" :default-current-page="1" hide-on-single-page :page-count="pages" :total="pages">
                </el-pagination>
            </template>
        </el-skeleton>
    </el-card>
</template>

<script lang="ts" setup>
    import { computed, onMounted, reactive, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import Api from '@/services/api'
    import { Answer, Quizz } from '@/services/types'

    const route = useRoute()
    const loading = ref<boolean>(true)
    const search = ref<string>('')
    const page = ref<number>(1)
    const limit = 10

    const quizz = reactive<Quizz>({
        title: '',
        short_description: '',
        day: null,
        from: null,
        to: null
    })

    // fetch answers
    onMounted(async () => {
        loading.value = true
        const response = await Api.get(`/quizz/${route.params.id}`)
        if (response.status >= 200 && response.status < 300) {
            const q = response.data.quizz
            console.log(response)
            
            quizz.title = q.title
            quizz.answers = q.answers
            quizz.day = q.day
            quizz.team = q.team
            loading.value = false
        } else {
            console.error('Error fetching teams:', response);
        }
    })

    const filteredData = computed(() =>{
        if (quizz.answers) {
            return quizz.answers.filter((answer: Answer) => 
                !search.value ||
                answer.player.name?.toLowerCase().includes(search.value.toLowerCase()) //||
                // answer.pain?.includes(search.value.toLowerCase())
            )
        }
        return []
    })

    const pages = computed(() =>
        Math.floor(filteredData.value.length / limit) +
        ((filteredData.value.length % limit > 0 && filteredData.value.length > limit) ? 1 : 0)
    )

    const sortProps = reactive<{
        prop: string | null
        order: 'asc' | 'desc' | null
    }>({
        prop: null,
        order: null
    })

    const setSortProps = (prop: string) => {
        if (sortProps.prop == prop) {
            if (sortProps.order == 'asc') { sortProps.order = 'desc'; return; }
            else if (sortProps.order == 'desc') { sortProps.order = null; return; }
        }
        sortProps.prop = prop
        sortProps.order = 'asc'
    }

    const sortedData = computed(() => {
        const prop = sortProps.prop
        const order = sortProps.order

        if (prop && order) {
            return filteredData.value.slice(0).sort((x, y) => {
                if (prop == 'name') {
                    if (x.player.name.toLowerCase() < y.player.name.toLowerCase()) { return order == 'asc' ? 1:-1 }
                    if (x.player.name.toLowerCase() > y.player.name.toLowerCase()) { return order == 'desc' ? 1:-1 }
                } else {
                    const xprop = typeof x[prop] == 'string' ? x[prop].toLowerCase() : x[prop]
                    const yprop = typeof y[prop] == 'string' ? y[prop].toLowerCase() : y[prop]
                    
                    if (xprop < yprop) { return order == 'asc' ? 1:-1 }
                    if (xprop > yprop) { return order == 'desc' ? 1:-1 }
                }
                return 0
            })
        }
        return filteredData.value.slice(0)
    })

    const showData = computed(() => {
        const first = (page.value - 1) * limit
        return sortedData.value?.slice(first, first + limit)
    })

    const tableProps = reactive<Array<{name: string, label: string, show: boolean}>>([
        {name: 'sleep', label: 'qualidade do sono', show: true},
        {name: 'pain_level', label: 'Nível de dor', show: false},
        {name: 'recovery', label: 'Recoperação', show: false},
        {name: 'urine', label: 'Coloração da urina', show: false},
    ])
</script>

<style lang="scss">
    .pointer {
        cursor: pointer;
    }
</style>
