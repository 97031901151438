import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "loginForm",
    rules: _ctx.rules,
    model: _ctx.user,
    class: "login-form",
    "label-position": "top",
    size: "large"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { class: "text-center h2 py-3" }, {
        default: _withCtx(() => [
          _createTextVNode(" Iniciar sessão ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "Insira seu email",
        prop: "email"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.user.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.email) = $event)),
            placeholder: "Email",
            "prefix-icon": "Message"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "Insira sua senha",
        prop: "password"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.user.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.password) = $event)),
            type: "password",
            placeholder: "Senha",
            "prefix-icon": "Lock",
            "show-password": ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            class: "login-button col-12 col-md-auto m-1",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleLogin(_ctx.loginForm))),
            loading: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode("Entrar")
            ]),
            _: 1
          }, 8, ["loading"]),
          _createVNode(_component_el_button, {
            disabled: _ctx.loading,
            plain: "",
            class: "create-account-button col-12 col-md-auto m-1",
            tag: "router-link",
            to: "/signup"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Criar Conta")
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { class: "forgot-password-link" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, { to: "/forgot-password" }, {
            default: _withCtx(() => [
              _createTextVNode("Esqueci a minha senha")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["rules", "model"]))
}