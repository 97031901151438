import axios from 'axios'
import { ElMessageBox } from 'element-plus'
import router from '@/router'

const baseUrl = 'https://api-carga.planejadordetreino.com/api'

const getAuthHeader = () => {
    const token = sessionStorage.getItem("token")
    return token ? { Authorization: `Bearer ${token}` } : {}
}

const instance = axios.create({
    baseURL: baseUrl
})

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            ElMessageBox.alert('Sua sessão expirou. Por favor, faça login.', 'Sessão Expirada', {
                confirmButtonText: 'OK',
                type: 'warning',
                callback: () => { router.push({ name: 'Login' }) }
            })
        }
        return Promise.reject(error)
    }
)

const Api = {
    async post(url: string, data: Record<string, any>) {
        const formData = new FormData()
        Object.keys(data).forEach(key => {
            formData.append(key, data[key])
        })

        try {
            const response = await instance.post(url, formData, {
                headers: {
                    ...getAuthHeader(),
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response
        } catch (error: any) {
            return error.response
        }
    },

    async get(url: string, params?: Record<string, any>) {
        try {
            const response = await instance.get(url, {
                headers: getAuthHeader(),
                params
            })
            return response
        } catch (error: any) {
            return error.response
        }
    },

    async put(url: string, data: Record<string, any>) {
        try {
            const response = await instance.put(url, data, {
                headers: {
                    ...getAuthHeader(),
                    'Content-Type': 'application/json'
                }
            })
            return response
        } catch (error: any) {
            return error.response
        }
    },

    async delete(url: string) {
        try {
            const response = await instance.delete(url, {
                headers: getAuthHeader()
            })
            return response
        } catch (error: any) {
            return error.response
        }
    }
}

export default Api
