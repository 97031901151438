import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_CaretTop = _resolveComponent("CaretTop")!
  const _component_CaretBottom = _resolveComponent("CaretBottom")!
  const _component_DCaret = _resolveComponent("DCaret")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_header, { class: "h2" }, {
      default: _withCtx(() => [
        _createTextVNode(" Listando meus clubes ")
      ]),
      _: 1
    }),
    _createVNode(_component_el_card, {
      shadow: "always",
      header: "Clubes",
      footer: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_skeleton, {
          loading: _ctx.loading,
          animated: "",
          rows: 7,
          class: "col-12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, {
              class: "py-3",
              justify: "end"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "text",
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                  size: "large",
                  placeholder: "Procurar clubes...",
                  class: "col-auto"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table, {
              data: _ctx.showData,
              height: "390",
              stripe: "",
              class: "col-12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  class: "col-auto",
                  width: 60
                }, {
                  default: _withCtx((data) => [
                    _createVNode(_component_el_row, {
                      justify: "start",
                      align: "middle",
                      gutter: 10,
                      class: "align-items-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { class: "align-self-center" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_avatar, {
                              alt: data.row.name
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("img", {
                                  src: 'https://api-carga.planejadordetreino.com/images/teams/' + data.row.logo
                                }, null, 8, _hoisted_1)
                              ]),
                              _: 2
                            }, 1032, ["alt"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  "show-overflow-tooltip": "",
                  prop: "name",
                  "min-width": 200
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("label", {
                      class: "col-12 pointer",
                      onClick: _cache[1] || (_cache[1] = () => _ctx.setSortProps('name'))
                    }, [
                      _createTextVNode(" Clube  "),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          (_ctx.sortProps.prop == 'name' && _ctx.sortProps.order == 'asc')
                            ? (_openBlock(), _createBlock(_component_CaretTop, { key: 0 }))
                            : (_ctx.sortProps.prop == 'name' && _ctx.sortProps.order == 'desc')
                              ? (_openBlock(), _createBlock(_component_CaretBottom, { key: 1 }))
                              : (_openBlock(), _createBlock(_component_DCaret, { key: 2 }))
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  prop: "category",
                  "min-width": 200
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("label", {
                      class: "col-12 pointer",
                      onClick: _cache[2] || (_cache[2] = () => _ctx.setSortProps('category'))
                    }, [
                      _createTextVNode(" Categoria  "),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          (_ctx.sortProps.prop == 'category' && _ctx.sortProps.order == 'asc')
                            ? (_openBlock(), _createBlock(_component_CaretTop, { key: 0 }))
                            : (_ctx.sortProps.prop == 'category' && _ctx.sortProps.order == 'desc')
                              ? (_openBlock(), _createBlock(_component_CaretBottom, { key: 1 }))
                              : (_openBlock(), _createBlock(_component_DCaret, { key: 2 }))
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  prop: "created_at",
                  width: 150
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("label", {
                      class: "col-12 pointer",
                      onClick: _cache[3] || (_cache[3] = () => _ctx.setSortProps('created_at'))
                    }, [
                      _createTextVNode(" Criada em  "),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          (_ctx.sortProps.prop == 'created_at' && _ctx.sortProps.order == 'asc')
                            ? (_openBlock(), _createBlock(_component_CaretTop, { key: 0 }))
                            : (_ctx.sortProps.prop == 'created_at' && _ctx.sortProps.order == 'desc')
                              ? (_openBlock(), _createBlock(_component_CaretBottom, { key: 1 }))
                              : (_openBlock(), _createBlock(_component_DCaret, { key: 2 }))
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "Ações",
                  "min-width": 200
                }, {
                  default: _withCtx((data) => [
                    _createVNode(_component_el_button, {
                      plain: "",
                      type: "warning",
                      icon: "Edit",
                      onClick: ($event: any) => (_ctx.navigate('/teams/' + data.row.id))
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_el_button, {
                      plain: "",
                      type: "primary",
                      onClick: ($event: any) => (_ctx.navigate('/players/' + data.row.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(data.row.players) + " jogadores ", 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"]),
            _createVNode(_component_el_pagination, {
              class: "py-4",
              onChange: _ctx.changePage,
              "default-current-page": 1,
              "hide-on-single-page": "",
              "page-count": _ctx.pages,
              total: _ctx.pages
            }, null, 8, ["onChange", "page-count", "total"])
          ]),
          _: 1
        }, 8, ["loading"])
      ]),
      _: 1
    })
  ], 64))
}