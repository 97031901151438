import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_header, { class: "h2" }, {
      default: _withCtx(() => [
        _createTextVNode(" Jogadores ")
      ]),
      _: 1
    }),
    _createVNode(_component_el_card, {
      shadow: "always",
      header: _ctx.team.name,
      footer: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_skeleton, {
          loading: _ctx.loading,
          animated: "",
          rows: 7,
          class: "col-12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, {
              class: "py-3",
              justify: "end"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "text",
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                  size: "large",
                  placeholder: "Procurar nome...",
                  class: "col-auto"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table, {
              data: _ctx.filteredData,
              height: "300",
              stripe: "",
              class: "col-12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { type: "index" }),
                _createVNode(_component_el_table_column, {
                  prop: "name",
                  label: "Jogador",
                  sortable: "",
                  "min-width": 200
                }),
                _createVNode(_component_el_table_column, {
                  prop: "created_at",
                  label: "Adicionado em",
                  sortable: "",
                  width: 150
                }),
                _createVNode(_component_el_table_column, {
                  "min-width": 50,
                  label: "Eliminar"
                }, {
                  default: _withCtx((data) => [
                    _createVNode(_component_el_button, {
                      plain: "",
                      icon: "Delete",
                      type: "danger",
                      onClick: ($event: any) => (_ctx.deletePlayer(data.row))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"]),
            _createVNode(_component_el_footer, { class: "py-3" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  icon: "ArrowLeftBold",
                  plain: "",
                  type: "primary",
                  bg: "",
                  size: "large",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.router.push('/teams/' + _ctx.team.id)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Voltar a equipa ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading"])
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}