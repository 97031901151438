<template>
    <router-view />
</template>
  
<script lang="ts">
    import { defineComponent } from 'vue';
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex';
    
    export default defineComponent({
        name: 'App',
        setup() {
            const router = useRouter()
            const store = useStore()

            router.beforeEach(route => {
                const path = route.path
                store.dispatch('navigate', path)
            })

            return {

            }
        }
    });
</script>

<style lang="scss">
    :root, body, #app {
        margin: 0px;
        padding: 0px;
    }
</style>
  