<template>
    <el-header class="h2">
        <el-skeleton animated :loading="loading">
            <template #template>
                <el-skeleton-item variant="h1"></el-skeleton-item>
            </template>
            <template #default>Adicionar jogador</template>
        </el-skeleton>
    </el-header>
    <el-row class="d-flex justify-content-center">
        <el-col :xs="24" :sm="18" :md="15" :lg="10">
            <el-card shadow="always" header="Novo clube" class="col-12">
                <el-form
                    :disabled="loading2"
                    label-position="top"
                    label-width="auto"
                    ref="formRef"
                    scroll-to-error
                    :rules="rules"
                    :model="data"
                    size="large"
                >
                    <el-skeleton animated :loading="loading">
                        <template #template>
                            <el-row align="top" :gutter="7" justify="start" class="col-12">
                                <el-col :xs="24">
                                    <el-skeleton-item variant="caption" class="my-1 mb-2 col-9 col-md-6" />
                                    <el-skeleton-item variant="button" class="col-12 mt-1 mb-4" />

                                    <el-skeleton-item variant="caption" class="my-1 mb-2 col-9 col-md-6" />
                                    <el-skeleton-item variant="button" class="col-12 my-1" />

                                    <el-skeleton-item variant="button" class="col-5 col-lg-4 m-0" />
                                </el-col>
                            </el-row>
                            <el-row :gutter="10" class="pt-4 mt-2">
                                <el-col :xs="24" :sm="12" class="py-1">
                                    <el-skeleton-item variant="button" class="col-12 col-md-4" />
                                </el-col>
                                <el-col :xs="24" :sm="12" class="py-1" style="text-align: right">
                                    <el-skeleton-item variant="button" class="col-12 col-md-3" />
                                </el-col>
                            </el-row>
                        </template>
                        <template #default>
                            <el-row align="top" :gutter="7" justify="start" class="col-12">
                                <el-col :xs="24">
                                    <el-form-item label="Clube dos jogadores" prop="team_id" required>
                                        <el-select filterable placeholder="Selecione um clube" v-model="data.team_id" @blur="validate" @change="validate">
                                            <template #header>
                                                <h6 class="h6">Meus clubes</h6>
                                            </template>
                                            <el-option v-for="team in teams" :key="team.id" :label="team.name" :value="team.id" class="row">
                                                <div class="col-auto position-relative d-inline-block">
                                                    <el-avatar :alt="team.name" size="small" class="position-absolute d-block" style="top: 0; bottom: 0; left: 0px; margin: auto !important">
                                                        <img :src="'https://api-carga.planejadordetreino.com/images/teams/' + team.logo" />
                                                    </el-avatar>
                                                </div>
                                                <div class="col-auto">
                                                    <span class="label px-2" style="height: 100% !important">{{ team.name }}</span>
                                                </div>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item 
                                        v-for="(player, i) in data.players" 
                                        :key="i" required 
                                        :label="'Jogador ' + (i + 1)" 
                                        class="col-12"
                                        :rules="{
                                            required: true,
                                            message: 'Por favor insira o nome do jogador',
                                            trigger: 'blur',
                                        }"
                                        :prop="`players.${i}`"
                                    >
                                        <el-input type="text" prefix-icon="User" placeholder="Insira o nome do jogador" v-model="data.players[i]" class="my-1"/>
                                        <el-button type="danger" plain icon="DeleteFilled" class="my-1" @click="() => { data.players.splice(i, 1)}">Remover</el-button>
                                    </el-form-item>

                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="12" class="py-1">
                                            <el-button :disabled="loading2" @click="() => { data.players.push('') }" class="col-12 col-md-auto" icon="Plus" size="large">
                                                Adicionar jogador
                                            </el-button>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" class="py-1" style="text-align: right">
                                            <el-button @click="send(formRef)" class="col-12 col-md-auto" icon="Check" type="primary" size="large" :loading="loading2">
                                                Cadastrar
                                            </el-button>
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </template>
                    </el-skeleton>
                </el-form>
            </el-card>
        </el-col>
    </el-row>
</template>

<script lang="ts">
    import Api from '@/services/api'
    import { ElMessage, ElMessageBox, FormInstance, FormItemContext, FormRules } from 'element-plus';
    import { defineComponent, onMounted, reactive, ref } from 'vue'
    import { useRouter } from 'vue-router';
    import { Team } from '@/services/types'

    interface Data {
        team_id: number | null
        players: Array<string>
    }

    export default defineComponent({
        name: 'NewPlayerView',
        setup() {
            
            const formRef = ref<FormInstance>()
            const loading = ref<boolean>(true)
            const loading2 = ref<boolean>(false)
            const router = useRouter()

            const teams = ref<Array<Team>>([])
            const data = reactive<Data>({
                team_id: null,
                players: ['']
            })

            const rules = reactive<FormRules<Data>>({
                players: [
                    {required: true, message: "Por favor insira pelo menos 1 jogador", trigger: ["blur", "change"]},
                ],
                team_id: [
                    {required: true, message: "Por favor escolha um clube", trigger: "blur"},
                    {type: 'number', message: "Por favor escolha um clube da lista", trigger: "blur"},
                ]
            })

            // get teams list
            onMounted(async () => {
                loading.value = true
                const res: any = await Api.get("/teams")
                if (res.status >= 200 && res.status < 300) {
                   teams.value = res.data.teams
                } else {
                    ElMessage.error({message: "Ocorre um erro"})
                }
                loading.value = false
            })

            const validate: () => boolean = () => {
                const find = teams.value.filter(({ id }) => id == data.team_id)
                const field : FormItemContext | undefined = formRef.value?.fields.filter(val => val.prop == "team_id")[0]
                if (field) {
                    if (find.length < 1) {
                        field.validateState = "error"
                    } else {
                        field.validateState = "success"
                        return true
                    }
                }
                return false
            }

            const send = async (formEl: FormInstance | undefined) => {
                if (!formEl) { return }
                await formEl.validate(async (valid) => {
                    if (valid && validate()) {
                        loading2.value = true
                        const copy = new Map<number, string>()
                        data.players.map((val, i) => { copy.set(i, val) })

                        // const done = []
                        copy.forEach(async (val, key) => {
                            const res = await Api.post("/players/" + data.team_id, {name: val})
                            if (res.status >= 200 && res.status < 300) {
                                copy.delete(key)
                                
                            } else if (res.status == 422) {
                                if (res.data.errors && res.data.errors.name) {
                                    ElMessage.error({message: res.data.errors.name[0]})
                                    const field : FormItemContext | undefined = formRef.value?.fields.filter(val => val.prop == "players." + key)[0]
                                    if (field) {
                                        field.validateState = "error"
                                    }
                                }
                            } else if (res.status == 404) {
                                ElMessageBox.alert("O clube nao foi encontrado");
                                loading2.value = false
                            }

                            if (key >= data.players.length - 1) {
                                // eliminar os cadastrados
                                data.players.splice(0, data.players.length)
                                
                                if (copy.size < 1) {
                                    loading2.value = false
                                    if (data.players.length < 1) {
                                        router.push("/players/" + data.team_id)
                                    }
                                }
                                copy.forEach((val, key) => {
                                    data.players.push(val)
                                    copy.delete(key)
                                    
                                    if (copy.size < 1) {
                                        loading2.value = false
                                        if (data.players.length < 1) {
                                            router.push("/players/" + data.team_id)
                                        }
                                    }
                                })
                            }
                        })

                    }
                })
            }
            
            return {
                loading,
                loading2,
                teams,
                data,
                blanck: "images/blanck.png",
                rules,
                formRef,
                router,
                validate,
                send
            }
        },
    })
</script>
