<template>
    <el-header class="h2">
        Jogadores
    </el-header>
    <el-card shadow="always" :header="team.name" footer="">
        <el-skeleton :loading="loading" animated :rows="7" class="col-12">
            <template #default>
                <el-row class="py-3" justify="end">
                    <el-input type="text" v-model="search" size="large" placeholder="Procurar nome..." class="col-auto" />
                </el-row>
                <el-table :data="filteredData" height="300" stripe class="col-12">
                    <el-table-column type="index" />
                    <el-table-column prop="name" label="Jogador" sortable :min-width="200" />
                    <el-table-column prop="created_at" label="Adicionado em" sortable :width="150" />
                    <el-table-column :min-width="50" label="Eliminar">
                        <template #default="data">
                            <el-button plain icon="Delete" type="danger" @click="deletePlayer(data.row)" />
                        </template>
                    </el-table-column>
                </el-table>
                <el-footer class="py-3">
                    <el-button icon="ArrowLeftBold" plain type="primary" bg size="large" @click="router.push('/teams/' + team.id)">
                        Voltar a equipa
                    </el-button>
                </el-footer>
            </template>
        </el-skeleton>
    </el-card>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, ref } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import Api from '@/services/api'
    import { ElMessage, ElMessageBox } from 'element-plus'
import { Player } from '@/services/types'
    

    export default defineComponent({
        setup() {
            document.title = "Jogadores"

            const router = useRouter()
            const loading = ref<boolean>(true)
            const search = ref<string>('')

            const team = ref<any>({});

            onMounted(async () => {
                loading.value = true
                const response = await Api.get('/players/' + useRoute().params.id)
                if (response.status >= 200 && response.status < 300) {
                    console.log(response)
                    response.data.team.players.forEach((player: any, i: number) => {
                        player.created_at = (new Date(player.created_at)).toLocaleDateString()
                        response.data.team.players[i] = player
                    })
                    team.value = response.data.team
                    loading.value = false

                } else {
                    console.error('Error fetching teams:', response);
                    ElMessageBox({"message": "Ocorreu um erro"})
                }
            })

            const filteredData = computed(() => 
                team.value.players.filter((player: any) => 
                    !search.value ||
                    player.name.toLowerCase().includes(search.value.toLowerCase())
                )
            )

            const deletePlayer = (player: Player) => {
                ElMessageBox.confirm(`Deseja realmente remover ${player.name} e todos os deus dados?`, {
                    type: 'warning',
                    confirmButtonClass: 'bg-danger bg-gradient',
                    confirmButtonText: 'Eliminar',
                    async beforeClose(action, instance, done) {
                        if (action == 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.cancelButtonLoading = true

                            // enviar request para eliminar
                            const res = await Api.delete(`/players/${player.id}`)
                            if (res.status != 200) {
                                ElMessage.error({
                                    message: "Ocorreu um erro\n" + res.data.message 
                                })
                            } else {
                                let index = -1
                                team.value.players.find((p: Player) => {
                                    index++
                                    return p.id == player.id
                                })

                                team.value.players.splice(index, 1)
                            }
                        }

                        done()
                    }
                }).then(a => {
                    // ignora
                }).catch(a => {
                    // cancelado
                })
            }
            return {
                team,
                loading,
                router,
                search,
                filteredData,
                deletePlayer
            }
        },
    })
</script>

