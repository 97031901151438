<template>
    <el-header class="h2">
        <el-skeleton animated :loading="loading">
            <template #template>
                <el-skeleton-item variant="h1"></el-skeleton-item>
            </template>
            <template #default>Adicionar um clube</template>
        </el-skeleton>
    </el-header>
    <el-row class="d-flex justify-content-center">
        <el-col :xs="24" :sm="18" :md="15" :lg="10">
            <el-card shadow="always" header="Novo clube" class="col-12">
                <el-form
                    label-position="top"
                    label-width="auto"
                    :model="team"
                    ref="formRef"
                    scroll-to-error
                    size="large"
                    :rules="rules"
                >
                    <el-skeleton animated :loading="loading">
                        <template #template>
                            <el-row align="top" :gutter="7" justify="start" class="col-12">
                                <el-col :xs="24" :md="12" :lg="9">
                                    <el-skeleton-item variant="text" class="mb-2 col-7" />
                                    <el-skeleton-item variant="image" class="mb-4" style="height: 140px">
                                    </el-skeleton-item>
                                </el-col>
                
                                <el-col :xs="24" :md="12" :lg="15" class="mb-4">
                                    <el-skeleton-item variant="text" class="mb-2 col-6" />
                                    <el-skeleton-item variant="button" class="col-12 mb-4" />
                                    <el-skeleton-item variant="text" class="mb-2 col-4" />
                                    <el-skeleton-item variant="button" class="col-12" />
                                </el-col>
                                
                                <el-col>
                                    <el-skeleton-item variant="button" />
                                </el-col>
                            </el-row>
                        </template>
                        <template #default>
                            <el-row align="top" :gutter="7" justify="start" class="col-12">
                                <el-col :xs="24" :md="12" :lg="9">
                                    <el-form-item prop="logo" required label="Imagem do clube" class="_upload"> 
                                        <img :src="previw" class="col-12" @click="selectFile">
                                        <el-input type="file" name="logo" v-model="team.logo" class="upload d-none" ref="fileRef" @change="changeFile"/>
                                    </el-form-item>
                                </el-col>
                
                                <el-col :xs="24" :md="12" :lg="15">
                                    <el-form-item prop="name" required label="Nome do clube" class="col-12">
                                        <el-input type="text" prefix-icon="Football" name="name" placeholder="Insira o nome do clube" v-model="team.name"/>
                                    </el-form-item>
                                    <el-form-item prop="category" required label="Categoria" class="col-12">
                                        <el-input type="text" prefix-icon="CollectionTag" name="category" placeholder="Insira a categoria" v-model="team.category" />
                                    </el-form-item>
                                </el-col>
        
                                <el-col>
                                    <el-button type="primary" :loading="loading2" size="large" @click="send(formRef)">
                                        Criar
                                    </el-button>
                                </el-col>
                            </el-row>
                        </template>
                    </el-skeleton>
                </el-form>
            </el-card>
        </el-col>
    </el-row>
</template>

<script lang="ts">
    import Api from '@/services/api';
    import { ElMessage, ElMessageBox, FormInstance, FormRules } from 'element-plus';
    import { defineComponent, onMounted, reactive, ref } from 'vue'
    
    import router from '@/router';
    import { Team } from '@/services/types'

    export default defineComponent({
        name: 'NewTeamView',
        setup() {

            const blanck = "images/blanck.png"
            
            const formRef = ref<FormInstance>()
            const user_id = ref<number>(0)
            const loading = ref<boolean>(true)
            const loading2 = ref<boolean>(false)
            const fileRef = ref()
            const previw = ref<string>('')
            previw.value = blanck 
            // get user id

            onMounted(async () => {
                loading.value = true
                const res: any = await Api.get("/auth/profile")
                if (res.status >= 200 && res.status < 300) {
                    user_id.value = res.data.user.id
                } else {
                    ElMessage.error({message: "Ocorre um erro"})
                }
                loading.value = false
            })

            const team = reactive<Team>({
                user_id: user_id.value,
                name: '',
                category: '',
                logo: null
            })

            const rules = reactive<FormRules<Team>>({
                name: [{required: true, message: "Por favor digite o nome"}],
                category: [{required: true, message: "Por favor digite a categoria"}],
                logo: [
                    {required: true, message: "Por favor selecione uma imagem"},
                ]
            })
            const selectFile = () => {
                fileRef.value.input.click()
            }

            const changeFile = () => {
                if (fileRef.value.input.files && fileRef.value.input.files.length > 0) {
                    previw.value  = URL.createObjectURL(fileRef.value.input.files[0])
                } else { 
                    previw.value = blanck
                }
            }

            const send = async (formEl: FormInstance | undefined) => {
                if (!formEl) { return }
                await formEl.validate(async (valid) => {
                    if (valid) {
                        loading2.value = true
                        const data = Object.assign({}, team)
                        data['logo'] = fileRef.value.input.files[0]
                        
                        const res = await Api.post("/teams", data)
                        if (res.status >= 200 && res.status < 300) {
                            // feito
                            router.push("/teams")
                        } else if (res.status == 422) {
                            if (res.data.errors) {
                                const erros = res.data.errors
                                formRef.value?.fields.forEach((val) => {
                                    Object.keys(erros).forEach(field => {
                                        if (val.prop == field) {
                                            val.validateState = "error"
                                            ElMessage({
                                                type: "error",
                                                message: res.data.errors[field][0]
                                            })
                                        }
                                    })
                                })
                            }
                            loading2.value = false
                        } else {
                            ElMessageBox.alert("Ocorreu um erro");
                            loading2.value = false
                        }
                    }
                })
            }
            
            return {
                loading,
                loading2,
                team,
                rules,
                fileRef,
                selectFile,
                changeFile,
                previw,
                blanck,
                send,
                formRef
            }
        },
    })
</script>

<style lang="scss" scoped>
    ._upload {
        position: relative;
        img {
            border: 1px dashed darkblue;
            top: 0;
            left: 0;
            height: auto;
            z-index: 3;
            background: whitesmoke;
            cursor: pointer;
            height: max-content !important;

            &:hover {
                border-color: blue;
            }
        }

        input {
            height: 0px !important;
            overflow: hidden !important;
            display: none !important;
        }
    }
</style>
