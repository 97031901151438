<template>
    <div class="login-view p-1">
        <el-card class="login-card col-12 col-sm-10 col-md-5 col-xl-4" shadow="hover">
            <router-view></router-view>
        </el-card>
    </div>
</template>
  
<script lang="ts">
    import { defineComponent } from 'vue';
    import logo from "@/assets/logo.png"
    
    export default defineComponent({
        name: 'LoginLayout',
        setup() {
            return {
                logo
            }
        }
    });
</script>
  
<style lang="scss" scoped>
    .login-view {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #2c3e50;
    }

    .forgot-password-link {
        text-align: center;
        margin-top: 10px;
    }
</style>
  