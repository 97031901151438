<template>
    <el-header class="h2">
        <el-skeleton animated :loading="loading">
            <template #template>
                <el-skeleton-item variant="h1"></el-skeleton-item>
            </template>
            <template #default>{{ quizz.title }}</template>
        </el-skeleton>
    </el-header>
    <el-row class="d-flex justify-content-center">
        <el-col :xs="24">
            <el-card shadow="always" class="col-12">
                <el-form
                    label-position="top"
                    label-width="auto"
                    :model="quizz"
                    :rules="rules"
                    ref="formRef"
                    scroll-to-error
                    size="large"
                >
                    <el-skeleton animated :loading="loading" :rows="7">
                        <template #default>
                            <el-row :gutter="10">
                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Clube" prop="team_id">
                                        <el-input v-if="quizz.team" v-model="quizz.team.name" disabled />
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Titulo a apresentar" prop="title" required>
                                        <el-input type="text" placeholder="Um pequeno titulo a ser mostrado" v-model="quizz.title" prefix-icon="View" />
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Data associada" prop="day" required>
                                        <el-date-picker format="YYYY/MM/DD" class="col-12" placeholder="inserir data de treino" v-model="quizz.day" :clearable="false" />
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Limites para responder" required prop="to">
                                        <el-date-picker :editable="false" end-placeholder="Até" start-placeholder="Responder de" :clearable="false" type="datetimerange" @change="changeRange" v-model="range" placeholder="Selcionar intervalo de data e hora" />
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Proteger com senha" prop="password">
                                        <el-input type="password" clearable placeholder="Senha (opcional)" show-password prefix-icon="Unlock" v-model="quizz.password"/>
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Mini descricao" required prop="short_description">
                                        <el-input type="textarea" maxlength="180" :show-word-limit="true" prefix-icon="Promotion" v-model="quizz.short_description" />
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Link de acesso" prop="permalink">
                                        <div class="col-12 form-control d-flex row m-0 justify-content-between">
                                            <span class="col-auto">{{ quizz.permalink }}</span>
                                            <el-button class="col-auto py-0" plain  icon="CopyDocument" @click="copy(quizz.permalink ? quizz.permalink:'')"></el-button>
                                        </div>
                                    </el-form-item>
                                </el-col>
    
                                <el-row :gutter="10" class="col-12">
                                    <el-col class="py-1 col-auto">
                                        <el-button type="primary" class="col-12 col-sm-auto" @click="send(formRef)" :loading="loading2">
                                            Atualizar
                                        </el-button>
                                    </el-col>
    
                                    <el-col class="py-1 col-auto" v-if="quizz.answers && quizz.to_anwer">
                                        <el-button plain icon="View" type="success" class="col-12 col-sm-auto" tag="router-link" :to="'/answers/' + quizz.id">
                                            {{ quizz.answers?.length }}/{{ quizz.to_anwer?.length + quizz.answers?.length }} respostas
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </el-row>

                        </template>
                    </el-skeleton>
                </el-form>
            </el-card>
        </el-col>
    </el-row>
</template>

<script lang="ts" setup>
    import Api from '@/services/api'
    import { ElMessage, ElMessageBox, FormInstance, FormRules } from 'element-plus'
    import { onMounted, reactive, ref } from 'vue'
    import { Quizz } from '@/services/types'
    import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

    document.title = "Novo questionário"
            
    const route = useRoute()
    const store = useStore()
    const formRef = ref<FormInstance>()
    const loading = ref<boolean>(true)
    const loading2 = ref<boolean>(false) 
    const quizz = reactive<Quizz>({
        team_id: null,
        password: null,
        title: '',
        short_description: '',
        day: null,
        from: null,
        to: null
    })
    const range = ref<Array<Date>>()
    const changeRange = () => {
        if (range.value) {
            [quizz.from, quizz.to] = range.value
        }
    }

    // fetch quizz
    onMounted(async () => {
        loading.value = true
        const res: any = await Api.get(`/quizz/${route.params.id}`)
        if (res.status >= 200 && res.status < 300) {
            const q = res.data.quizz

            console.log(q)

            quizz.created_at = q.created_at
            quizz.day = new Date(q.day)
            quizz.from = new Date(q.from)
            quizz.to = new Date(q.to)
            range.value = [quizz.from, quizz.to]
            quizz.short_description = q.short_description
            quizz.team = q.team
            quizz.title = q.title
            quizz.id = q.id
            quizz.answers = q.answers
            quizz.to_anwer = q.to_answer
            quizz.permalink = `${store.getters.baseRUrl}${q.permalink}`
        } else {
            ElMessage.error({message: "Ocorre um erro"})
            ElMessage.error({message: res.data.message})
        }
        loading.value = false
    })

    const rules = reactive<FormRules<Quizz>>({
        title: [{required: true, message: "Por favor preencha um título", trigger: "blur"}],
        short_description: [{required: true, message: "Por favor forneça uma descrição", trigger: "blur"}],
        day: [{required: true, message: "Preencha a data do treino para controle", trigger: "blur"}],
        to: [{required: true, message: "Estabeleca os limites para responder", trigger: "blur"}],
        from: [{required: true, message: "Estabeleca os limites para responder", trigger: "blur"}],
    })

    const add0: (n: number) => string = (n: number) => n < 10 ? `0${n}` : `${n}`

    const send = async (formEl: FormInstance | undefined) => {
        if (!formEl) { return }
        await formEl.validate(async (valid) => {
            if (valid) {
                loading2.value = true

                const copy: any = {
                    title: quizz.title,
                    short_description: quizz.short_description
                }
                if (quizz.password && quizz.password.length > 0) {
                    copy.password = quizz.password
                }
                let date: Date | null = quizz.to
                if (date) {
                    copy.to = `${date?.getFullYear()}-${add0(date.getMonth() + 1)}-${add0(date.getDate())} ${add0(date.getHours())}:${add0(date.getMinutes())}:${add0(date.getSeconds())}`
                }
                date = quizz.from
                if (date) {
                    copy.from = `${date?.getFullYear()}-${add0(date.getMonth() + 1)}-${add0(date.getDate())} ${add0(date.getHours())}:${add0(date.getMinutes())}:${add0(date.getSeconds())}`
                }
                date = quizz.day
                if (date) {
                    copy.day = `${date?.getFullYear()}-${add0(date.getMonth() + 1)}-${add0(date.getDate())}`
                }

                const res = await Api.put(`/quizz/${quizz.id}`, copy)
                if (res.status >= 200 && res.status < 300) {
                    ElMessage.success({message: "Atualizado com sucesso"})
                } else if (res.status == 422) {
                    if (res.data.errors) {
                        const erros = res.data.errors
                        formRef.value?.fields.forEach((val) => {
                            Object.keys(erros).forEach(field => {
                                if (val.prop == field) {
                                    val.validateState = "error"
                                    ElMessage({
                                        type: "error",
                                        message: res.data.errors[field][0]
                                    })
                                }
                            })
                        })
                    }
                } else {
                    console.log(res)
                    ElMessageBox.alert("Ocorreu um erro")
                }
                loading2.value = false
            }
        })
    }

    const copy = async (text: string = store.getters.baseRUr) => {
        await navigator.clipboard.writeText(text)
        ElMessage.success("Copiado para a área de transferência")
    }
</script>

<style lang="scss" scoped>
</style>
