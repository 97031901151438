import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12 col-md-6 col-lg-auto" }
const _hoisted_2 = { class: "col-12 col-md-6 col-lg-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_CaretTop = _resolveComponent("CaretTop")!
  const _component_CaretBottom = _resolveComponent("CaretBottom")!
  const _component_DCaret = _resolveComponent("DCaret")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_header, { class: "h2" }, {
      default: _withCtx(() => [
        _createTextVNode(" Listando Questionarios ")
      ]),
      _: 1
    }),
    _createVNode(_component_el_card, {
      shadow: "always",
      header: "Questionarios",
      footer: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_skeleton, {
          loading: _ctx.loading,
          animated: "",
          rows: 7,
          class: "col-12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, {
              class: "row justify-content-end m-0 p-0 py-3",
              justify: "end"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_el_date_picker, {
                    size: "large",
                    editable: false,
                    format: "YYYY/MM/DD",
                    modelValue: _ctx.date,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
                    placeholder: "selecionar data"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_el_input, {
                    type: "text",
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                    size: "large",
                    placeholder: "Procurar título ou clube...",
                    class: "col-12"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table, {
              data: _ctx.showData,
              stripe: "",
              class: "col-12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { type: "index" }),
                _createVNode(_component_el_table_column, {
                  "show-overflow-tooltip": "",
                  "min-width": 200,
                  prop: "team.name"
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("label", {
                      class: "col-12 pointer",
                      onClick: _cache[2] || (_cache[2] = () => _ctx.setSortProps('team.name'))
                    }, [
                      _createTextVNode(" Clube  "),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          (_ctx.sortProps.prop == 'team.name' && _ctx.sortProps.order == 'asc')
                            ? (_openBlock(), _createBlock(_component_CaretTop, { key: 0 }))
                            : (_ctx.sortProps.prop == 'team.name' && _ctx.sortProps.order == 'desc')
                              ? (_openBlock(), _createBlock(_component_CaretBottom, { key: 1 }))
                              : (_openBlock(), _createBlock(_component_DCaret, { key: 2 }))
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  prop: "title",
                  "min-width": 200
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("label", {
                      class: "col-12 pointer",
                      onClick: _cache[3] || (_cache[3] = () => _ctx.setSortProps('title'))
                    }, [
                      _createTextVNode(" Título  "),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          (_ctx.sortProps.prop == 'title' && _ctx.sortProps.order == 'asc')
                            ? (_openBlock(), _createBlock(_component_CaretTop, { key: 0 }))
                            : (_ctx.sortProps.prop == 'title' && _ctx.sortProps.order == 'desc')
                              ? (_openBlock(), _createBlock(_component_CaretBottom, { key: 1 }))
                              : (_openBlock(), _createBlock(_component_DCaret, { key: 2 }))
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  prop: "day",
                  "min-width": 200
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("label", {
                      class: "col-12 pointer",
                      onClick: _cache[4] || (_cache[4] = () => _ctx.setSortProps('day'))
                    }, [
                      _createTextVNode(" Data associada  "),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          (_ctx.sortProps.prop == 'day' && _ctx.sortProps.order == 'asc')
                            ? (_openBlock(), _createBlock(_component_CaretTop, { key: 0 }))
                            : (_ctx.sortProps.prop == 'day' && _ctx.sortProps.order == 'desc')
                              ? (_openBlock(), _createBlock(_component_CaretBottom, { key: 1 }))
                              : (_openBlock(), _createBlock(_component_DCaret, { key: 2 }))
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "Status",
                  "filter-multiple": false,
                  "min-width": 110,
                  filters: [
                            { value: '1', text: 'Pendente' },
                            { value: '2', text: 'Disponivel' },
                            { value: '3', text: 'Fechado' },
                        ],
                  "filter-method": _ctx.filterStatus
                }, {
                  default: _withCtx((data) => [
                    _createVNode(_component_el_tag, {
                      type: new Date(data.row.from) > new Date() ? 'info' : (new Date(data.row.to) > new Date() ? 'success':'warning')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(new Date(data.row.from) > new Date() ? 'Pendente' : (new Date(data.row.to) > new Date() ? 'Disponivel':'Fechado')), 1)
                      ]),
                      _: 2
                    }, 1032, ["type"])
                  ]),
                  _: 1
                }, 8, ["filter-method"]),
                _createVNode(_component_el_table_column, {
                  label: "Ações",
                  "min-width": 200
                }, {
                  default: _withCtx((data) => [
                    _createVNode(_component_el_tooltip, { content: "Copiar url" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          icon: "CopyDocument",
                          plain: "",
                          onClick: ($event: any) => (_ctx.copy(data.row.permalink))
                        }, null, 8, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_button, {
                      plain: "",
                      type: "primary",
                      icon: "View",
                      onClick: ($event: any) => (_ctx.navigate('/quizzs/' + data.row.id))
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_el_button, {
                      plain: "",
                      type: "danger",
                      onClick: ($event: any) => (_ctx.deleteQuizz(data.row.id)),
                      icon: "Delete"
                    }, null, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"]),
            _createVNode(_component_el_pagination, {
              class: "py-4",
              onChange: _ctx.changePage,
              "default-current-page": 1,
              "hide-on-single-page": "",
              "page-count": _ctx.pages,
              total: _ctx.pages
            }, null, 8, ["onChange", "page-count", "total"])
          ]),
          _: 1
        }, 8, ["loading"])
      ]),
      _: 1
    })
  ], 64))
}