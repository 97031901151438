import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-fba56640"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_header, { class: "h2" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_skeleton, {
          animated: "",
          loading: _ctx.loading
        }, {
          template: _withCtx(() => [
            _createVNode(_component_el_skeleton_item, { variant: "h1" })
          ]),
          default: _withCtx(() => [
            _createTextVNode("Adicionar um clube")
          ]),
          _: 1
        }, 8, ["loading"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { class: "d-flex justify-content-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          xs: 24,
          sm: 18,
          md: 15,
          lg: 10
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_card, {
              shadow: "always",
              header: "Novo clube",
              class: "col-12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  "label-position": "top",
                  "label-width": "auto",
                  model: _ctx.team,
                  ref: "formRef",
                  "scroll-to-error": "",
                  size: "large",
                  rules: _ctx.rules
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_skeleton, {
                      animated: "",
                      loading: _ctx.loading
                    }, {
                      template: _withCtx(() => [
                        _createVNode(_component_el_row, {
                          align: "top",
                          gutter: 7,
                          justify: "start",
                          class: "col-12"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_col, {
                              xs: 24,
                              md: 12,
                              lg: 9
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_skeleton_item, {
                                  variant: "text",
                                  class: "mb-2 col-7"
                                }),
                                _createVNode(_component_el_skeleton_item, {
                                  variant: "image",
                                  class: "mb-4",
                                  style: {"height":"140px"}
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_col, {
                              xs: 24,
                              md: 12,
                              lg: 15,
                              class: "mb-4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_skeleton_item, {
                                  variant: "text",
                                  class: "mb-2 col-6"
                                }),
                                _createVNode(_component_el_skeleton_item, {
                                  variant: "button",
                                  class: "col-12 mb-4"
                                }),
                                _createVNode(_component_el_skeleton_item, {
                                  variant: "text",
                                  class: "mb-2 col-4"
                                }),
                                _createVNode(_component_el_skeleton_item, {
                                  variant: "button",
                                  class: "col-12"
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_skeleton_item, { variant: "button" })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_row, {
                          align: "top",
                          gutter: 7,
                          justify: "start",
                          class: "col-12"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_col, {
                              xs: 24,
                              md: 12,
                              lg: 9
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_form_item, {
                                  prop: "logo",
                                  required: "",
                                  label: "Imagem do clube",
                                  class: "_upload"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("img", {
                                      src: _ctx.previw,
                                      class: "col-12",
                                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectFile && _ctx.selectFile(...args)))
                                    }, null, 8, _hoisted_1),
                                    _createVNode(_component_el_input, {
                                      type: "file",
                                      name: "logo",
                                      modelValue: _ctx.team.logo,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.team.logo) = $event)),
                                      class: "upload d-none",
                                      ref: "fileRef",
                                      onChange: _ctx.changeFile
                                    }, null, 8, ["modelValue", "onChange"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_col, {
                              xs: 24,
                              md: 12,
                              lg: 15
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_form_item, {
                                  prop: "name",
                                  required: "",
                                  label: "Nome do clube",
                                  class: "col-12"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_input, {
                                      type: "text",
                                      "prefix-icon": "Football",
                                      name: "name",
                                      placeholder: "Insira o nome do clube",
                                      modelValue: _ctx.team.name,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.team.name) = $event))
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_form_item, {
                                  prop: "category",
                                  required: "",
                                  label: "Categoria",
                                  class: "col-12"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_input, {
                                      type: "text",
                                      "prefix-icon": "CollectionTag",
                                      name: "category",
                                      placeholder: "Insira a categoria",
                                      modelValue: _ctx.team.category,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.team.category) = $event))
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_button, {
                                  type: "primary",
                                  loading: _ctx.loading2,
                                  size: "large",
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.send(_ctx.formRef)))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Criar ")
                                  ]),
                                  _: 1
                                }, 8, ["loading"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}