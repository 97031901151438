<template>
    <el-header class="h2">
        Listando meus clubes
    </el-header>
    <el-card shadow="always" header="Clubes" footer="">
        <el-skeleton :loading="loading" animated :rows="7" class="col-12">
            <template #default>
                <el-row class="py-3" justify="end">
                    <el-input type="text" v-model="search" size="large" placeholder="Procurar clubes..." class="col-auto" />
                </el-row>
                <el-table :data="showData" height="390" stripe class="col-12">
                    <el-table-column class="col-auto" :width="60">
                        <template #default="data">
                            <el-row justify="start" align="middle" :gutter="10" class="align-items-center">
                                <el-col class="align-self-center">
                                    <el-avatar :alt="data.row.name">
                                        <img :src="'https://api-carga.planejadordetreino.com/images/teams/' + data.row.logo">
                                    </el-avatar>
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="name" :min-width="200">
                        <template #header>
                            <label class="col-12 pointer" @click="() => setSortProps('name')">
                                Clube&emsp;
                                <el-icon>
                                    <CaretTop v-if="sortProps.prop == 'name' && sortProps.order == 'asc'" />
                                    <CaretBottom v-else-if="sortProps.prop == 'name' && sortProps.order == 'desc'" />
                                    <DCaret v-else/>
                                </el-icon>
                            </label> 
                        </template>
                    </el-table-column>
                    <el-table-column prop="category" :min-width="200">
                        <template #header>
                            <label class="col-12 pointer" @click="() => setSortProps('category')">
                                Categoria&emsp;
                                <el-icon>
                                    <CaretTop v-if="sortProps.prop == 'category' && sortProps.order == 'asc'" />
                                    <CaretBottom v-else-if="sortProps.prop == 'category' && sortProps.order == 'desc'" />
                                    <DCaret v-else/>
                                </el-icon>
                            </label> 
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" :width="150">
                        <template #header>
                            <label class="col-12 pointer" @click="() => setSortProps('created_at')">
                                Criada em&emsp;
                                <el-icon>
                                    <CaretTop v-if="sortProps.prop == 'created_at' && sortProps.order == 'asc'" />
                                    <CaretBottom v-else-if="sortProps.prop == 'created_at' && sortProps.order == 'desc'" />
                                    <DCaret v-else/>
                                </el-icon>
                            </label> 
                        </template>
                    </el-table-column>
                    <el-table-column label="Ações" :min-width="200">
                        <template #default="data">
                            <el-button plain type="warning" icon="Edit" @click="navigate('/teams/' + data.row.id)">
                            </el-button>
                            <el-button plain type="primary" @click="navigate('/players/' + data.row.id)">
                                {{ data.row.players }} jogadores
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="py-4" @change="changePage" :default-current-page="1" hide-on-single-page :page-count="pages" :total="pages">
                </el-pagination>
            </template>
        </el-skeleton>
    </el-card>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
    import { useRouter } from 'vue-router'
    import Api from '@/services/api'
    

    export default defineComponent({
        setup() {

            const router = useRouter()
            const navigate = (url: string) => { router.push(url) }
            const loading = ref<boolean>(true)
            const search = ref<string>('')
            const page = ref<number>(1)
            const limit = 10

            const teams = reactive<Array<any>>([]);

            onMounted(async () => {
                loading.value = true
                const response = await Api.get('/teams')
                if (response.status >= 200 && response.status < 300) {
                    response.data.teams.forEach((team: any, i: number) => {
                        team.created_at = (new Date(team.created_at)).toLocaleDateString()
                        teams[i] = team
                    })
                    loading.value = false
                } else {
                    console.error('Error fetching teams:', response);
                }
            })

            const filteredData = computed(() =>
                teams.filter((team: any) => 
                    !search.value ||
                    team.name.toLowerCase().includes(search.value.toLowerCase()) ||
                    team.category.toLowerCase().includes(search.value.toLowerCase())
                )
            )

            const pages = computed(() =>
                Math.floor(filteredData.value.length / limit) +
                ((filteredData.value.length % limit > 0 && filteredData.value.length > limit) ? 1 : 0)
            )

            const sortProps = reactive<{
                prop: string | null
                order: 'asc' | 'desc' | null
            }>({
                prop: null,
                order: null
            })

            const setSortProps = (prop: string) => {
                if (sortProps.prop == prop) {
                    if (sortProps.order == 'asc') { sortProps.order = 'desc'; return; }
                    else if (sortProps.order == 'desc') { sortProps.order = null; return; }
                }
                sortProps.prop = prop
                sortProps.order = 'asc'
            }

            const sortedData = computed(() => {
                const prop = sortProps.prop
                const order = sortProps.order
                if (prop && order) {
                    return filteredData.value.slice(0).sort((x, y) => {
                        if (x[prop].toLowerCase() < y[prop].toLowerCase()) { return order == 'asc' ? 1:-1 }
                        if (x[prop].toLowerCase() > y[prop].toLowerCase()) { return order == 'desc' ? 1:-1 }
                        return 0
                    })
                }
                return filteredData.value.slice(0)
            })

            const showData = computed(() => {
                const first = (page.value - 1) * limit
                return sortedData.value?.slice(first, first + limit)
            })

            return {
                navigate,
                loading,
                search,
                page,
                pages,
                showData,
                sortProps,
                setSortProps,
                changePage: (n: number) => page.value = n
            }
        },
    })
</script>

<style lang="scss">
    .pointer {
        cursor: pointer;
    }
</style>
