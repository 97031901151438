<template>
    <div>
        <el-row class="p-3 row m-0 justify-content-center align-items-center">
            <el-col :xs="24" :md="18" :lg="12" :xl="11">
                <el-col class="p-2">
                    <el-card>
                        <el-skeleton :rows="15" animated :loading="loading">
                            <template #default v-if="quizz">
                                <h2 class="h3">{{ quizz.title }}</h2>
                                <hr />
                                <p> {{ quizz.short_description }}</p>
                                <el-row align="middle" justify="center" class="p-3">
                                    <el-col :xs="24" :md="18" :xl="16">
                                        <el-image class="col-12" :src="'https://api-carga.planejadordetreino.com/images/teams/' + quizz.team.logo" />
                                        <h4 class="h4 col-12 text-center">{{ quizz.team.name }} </h4>
                                        <span class="small muted text-center col-12 d-block">{{ quizz.team.category }}</span>
                                    </el-col>
                                </el-row>
                            </template>
                        </el-skeleton>
                        <el-empty v-if="!loading && !quizz" description=":(">
                            <template #default>
                                <h2 class="h3 text-danger">
                                    {{ out ? 'Fora do limite estabecido' : 'Formulário não encontrado' }}
                                </h2>
                                <p>
                                    {{ out ? 
                                        `Este formulário está atualmente fora dos horários para responder 
                                        estabeliecidos pelo treinador. Por favor, contacte o treinador para 
                                        conhecer os horários corretos` :
                                        `Este formulário não foi encontrado em nosso sistema de controle, 
                                        pode ter sido eliminado ou o link está incorreto. Por favor 
                                        contacte o seu treinador para obter o link correto`
                                    }}
                                </p>
                            </template>
                        </el-empty>
                    </el-card>
                </el-col>

                <el-col class="p-2" v-if="done">
                    <el-card class="text-center">
                        <h4 class="h4 text-success text-center">
                            Respostas enviadas com sucesso
                        </h4>
                        <p class="text-center">{{ done }}, Suas respostas ao questionário foram enviada com sucesso para o clube</p>
                    </el-card>
                </el-col>

                <el-form :disabled="loading2" v-if="!done && !loading && quizz" size="large" label-position="top" :model="answer" @submit.prevent="submit">
                    <el-col class="p-2">
                        <el-card>
                            <el-form-item prop="player_id" label="Selcione o seu nome" required>
                                <el-select :allow-create="false" v-model="answer.player_id" filterable placeholder="Pesquise seu nome">
                                    <el-option v-for="player in quizz.team.players" 
                                        :key="player.id" 
                                        :label="player.name" 
                                        :value="player.id" 
                                        :disabled="player.answer" 
                                    />
                                </el-select>
                            </el-form-item>
                        </el-card>
                    </el-col>

                    <template v-if="answer.player_id">
                        <!-- Qualidade do sono -->
                        <el-col class="p-2">
                            <el-card>
                                <el-form-item prop="sleep" label="Qualidade do sono" required>
                                    <el-slider v-model="answer.sleep" show-stops placement="right" :max="5" :marks="sleeps" 
                                        :min="1" :step="1" :format-tooltip="formatSleep" vertical height="160px"
                                    />
                                </el-form-item>
                            </el-card>
                        </el-col>
                        <!-- ./Qualidade do sono -->
    
                        <!-- Nivel de dor -->
                        <el-col class="p-2">
                            <el-card>
                                <el-form-item prop="pain_level" label="Nivel de dor" required>
                                    <img :src="painLevelImage" class="col-12" />
                                    <el-slider v-model="answer.pain_level" show-stops class="p-2" placement="top" :max="10"
                                        :min="0" :step="1" :marks="pain_levelMarks" :format-tooltip="formatPain"
                                    />
                                </el-form-item>
                            </el-card>
                        </el-col>
                        <!-- ./Nivel de dor -->
    
                        <!-- Local da dor -->
                        <el-col class="p-2" v-if="answer.pain_level > 0">
                            <el-card>
                                <el-form-item id="pain" prop="pain" label="Local da dor" required>
                                    Clique nos locais onde tem dor
                                    <img class="col-12" :src="painLocalImage" />
                                    <el-checkbox v-for="i in painLocations" :key="i" :label="i" @change="selectBodyPart" :name="i" />
                                    <el-checkbox v-for="i in 22" :key="i" :label="i" @change="selectBodyPart" :name="`${i}`" />
                                </el-form-item>
                            </el-card>
                        </el-col>
                        <!-- ./Local da dor -->

                        <!-- Nivel de sstresse -->
                        <el-col class="p-2">
                            <el-card>
                                <el-form-item prop="stress" label="Nivel de estresse" required>
                                    <el-slider v-model="answer.stress" show-stops placement="right" :max="5" :marks="stress" 
                                        :min="1" :step="1" :format-tooltip="formatStress" vertical height="160px"
                                    />
                                </el-form-item>
                            </el-card>
                        </el-col>
                        <!-- ./Nivel de sstresse -->

                        <!-- Recuperacao -->
                        <el-col class="p-2">
                            <el-card>
                                <el-form-item prop="recovery" label="Percepção subjetiva de Recypperação" required>
                                    <img :src="recoveryImage" class="col-12" />
                                    <el-slider v-model="answer.recovery" show-stops class="p-2" placement="top" :max="10"
                                        :min="0" :step="1" :marks="pain_levelMarks"
                                    />
                                </el-form-item>
                            </el-card>
                        </el-col>
                        <!-- ./Recuperacao -->

                        <!-- Urina -->
                        <el-col class="p-2">
                            <el-card>
                                <el-form-item prop="urine" label="Coloração da urina" required>
                                    <span v-for="urine, i in urines" :key="i" class="p-2 col-12 col-md-3 col-lg-4">
                                        <div @click="() => answer.urine = i + 1" :class="{'active': answer.urine - 1 == i}" class="col-12 urine p-4 p-5 h5 text-center"  :style="{backgroundColor: urine}">{{ i + 1 }}</div>
                                    </span>
                                </el-form-item>
                            </el-card>
                        </el-col>
                        <!-- ./Urina -->

                        <el-col class="p-2 text-end">
                            <el-button native-type="submit" type="primary" :loading="loading2" icon="Check" size="large">
                                Enviar respostas
                            </el-button>
                        </el-col>
                    </template>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script lang="ts" setup>
    import Api from "@/services/api";
    import { Answer } from "@/services/types";
    import { ElMessage, ElMessageBox } from "element-plus";
    import { CSSProperties, onMounted, reactive, ref } from "vue";
    import { useRoute, useRouter } from "vue-router";

    const painLevelImage = "/images/pain-level.jpg"
    const painLocalImage = "/images/pain-loc.jpg"
    const recoveryImage = "/images/recovery.png"

    const route = useRoute()
    const router = useRouter()
    const quizz = ref<any>(null)
    const answer = reactive<Answer>({
        quizz_id: null,
        player_id: null,
        sleep: 5,
        pain_level: 0,
        stress: 5,
        recovery: 1,
        urine: 1,
        pain: [],
        player: null
    })
    const loading = ref<boolean>(true)
    const loading2 = ref<boolean>(false)
    const out = ref<boolean>(true)
    const password = ref<string | false>(false)
    const done = ref<string | null>(null)

    const fetchQuizz = async () => {
        loading.value = true
        const res = await Api.get("/r/" + route.params.permalink + (password.value ? `?password=${password.value}`:''))
        if (res.status >= 200 && res.status < 300) {
            quizz.value = res.data.quizz
            loading.value = false
        } else if (res.status == 404) {
            out.value = false
            quizz.value = null
            ElMessage.error({message: "Pedido nao encontrado"})
            loading.value = false
        } else if(res.status == 422) {
            // senha
            ElMessageBox.prompt("Insira a senha fornecida pelo treinador: ", "Senha requerida", {
                type: 'warning',
                showClose: false,
                inputType: 'password',
                beforeClose: ((action, instance, done) => {
                    console.log(instance.inputValue)
                    password.value = instance.inputValue
                    fetchQuizz()
                    done()
                })
            }).catch()
        } else if (res.status == 403) {
            // senha errad
            ElMessageBox.prompt("A senha fornecida esta errada, tente novamente: ", "Senha errada", {
                type: 'error',
                inputType: 'password',
                showClose: false,
                beforeClose: ((action, instance, done) => {
                    console.log(instance.inputValue)
                    password.value = instance.inputValue
                    fetchQuizz()
                    done()
                })
            }).catch()
        } else {
            ElMessage.error("Ocorreu um erro ao buscar o pedido")
            loading.value = false
        }
    }

    const selectBodyPart = (active: any, {target}: any) => {
        if (active) {
            answer.pain.push(target.name)
        } else {
            if (answer.pain.indexOf(target.name) >= 0) {
                answer.pain.splice(answer.pain.indexOf(target.name), 1)
            }
        }
    }

    const sleeps = reactive<Record<number, {style: CSSProperties, label: string}>>({
        1: {style: {color: '#ff2600', paddingLeft: '0.5rem', fontWeight: 'bold', fontSize: '1.3em'}, label: 'Insónia'},
        2: {style: {color: '#ff9703', paddingLeft: '0.5rem', fontWeight: 'bold', fontSize: '1.3em'}, label: 'Sono inquieto'},
        3: {style: {color: '#eff908', paddingLeft: '0.5rem', fontWeight: 'bold', fontSize: '1.3em'}, label: 'Dificuldade de adormecer'},
        4: {style: {color: '#83d926', paddingLeft: '0.5rem', fontWeight: 'bold', fontSize: '1.3em'}, label: 'Bom'},
        5: {style: {color: '#1db949', paddingLeft: '0.5rem', fontWeight: 'bold', fontSize: '1.3em'}, label: 'Muito transquilo'},
    })

    const urines = reactive<Array<string>>([
        '#ffffeb', '#fff9bd', '#feeb6b', '#ffd859', '#ffc430', '#ffb907', '#ffaa01', '#bd7d01'
    ])

    const stress = reactive<Record<number, {style: CSSProperties, label: string}>>({})
    for(let prop in sleeps) {
        stress[prop] = Object.assign({}, sleeps[prop])
        stress[prop].label = ['', 'Altamente estressado', 'Sinto-me estressado', 'Normal', 'Relaxado', 'Muito relaxado'][prop]
    }

    const marks: (from: number, to: number) => Record<number, any> = (from: number, to: number) => {
        const mks: Record<number, any> = {}
        for(let i = from; i <= to; i++) {
            mks[i] = {label: i}
        }

        return mks
    }

    const pain_levelMarks = marks(0, 10)
    const painLocations: Array<string> = 'ABCDEFGHIJKLMNOPQR'.split('')

    const formatSleep = (n: number) => sleeps[n].label;
    const formatStress = (n: number) => stress[n].label;

    const formatPain = (n: number) => ['sem dor', 'sem dor', 'suave', 'suave', 'moderada', 'moderada', 'forte', 'forte', 'muito forte', 'muito forte', 'maxima', 'maxima'][n]
    // fetch quizz
    onMounted(fetchQuizz)

    // enviar resposta ao servidor
    const submit = async () => {
        loading2.value = true
        if (answer.pain_level > 0 && answer.pain.length < 1) {
            ElMessageBox.alert("Indique os locais onde tem dor")
            router.push("#pain")
            loading2.value = false
            return false
        } 

        // enviar formulario e esperar
        answer.password = password.value

        const data: any = Object.assign({}, answer)

        answer.pain.map((item, i) => {
            data[`pain[${i}]`] = item.toLocaleLowerCase()
        })

        delete data.pain

        console.log(data)
        const res = await Api.post(`/r/${route.params.permalink}`, data)
        console.log(res)
        if (res.status >= 200 && res.status < 300) {
            done.value = quizz.value.team.players.find((p: any) => p.id == answer.player_id).name
            ElMessageBox.alert("Respostas enviadas")
        } else if (res.status == 422) {
            ElMessageBox.alert("Ocorreu um erro nos dados")
            for(let prop in res.data.errors) {
                ElMessage.error({
                    message: res.data.errors[prop][0]
                })
            }
        } else if (res.status == 403) {
            ElMessageBox.alert("Senha erra, atualize a pagina e tente novamente")
        } else if (res.status == 400) {
            ElMessageBox.alert("Questionario fora do limite estabelecido para responder")
        }
        loading2.value = false
    }
</script>

<style lang="scss" scoped>
    .urine {
        transition: all .21s ease-in-out;
        cursor: pointer;
        &:hover {
            border: 2px dotted #0b43ff;
            transform: scale(1.05);
        }

        &.active {
            border: 3px solid #0b43ff;
            color: #0b43ff;
        }
    } 
</style>
