<template>
    <el-header class="h2">
        <el-skeleton animated :loading="loading">
            <template #template>
                <el-skeleton-item variant="h1"></el-skeleton-item>
            </template>
            <template #default>Info do clube</template>
        </el-skeleton>
    </el-header>
    <el-row class="d-flex justify-content-center">
        <el-col :xs="24" :sm="18" :md="15" :lg="10">
            <el-card shadow="always" :header="team.name" class="col-12">
                <el-form
                    label-position="top"
                    label-width="auto"
                    :model="team"
                    ref="formRef"
                    scroll-to-error
                    size="large"
                    :rules="rules"
                >
                    <el-skeleton animated :loading="loading">
                        <template #template>
                            <el-row align="top" :gutter="7" justify="start" class="col-12">
                                <el-col :xs="24" :md="12" :lg="9">
                                    <el-skeleton-item variant="text" class="mb-2 col-7" />
                                    <el-skeleton-item variant="image" class="mb-4" style="height: 140px">
                                    </el-skeleton-item>
                                </el-col>
                
                                <el-col :xs="24" :md="12" :lg="15" class="mb-4">
                                    <el-skeleton-item variant="text" class="mb-2 col-6" />
                                    <el-skeleton-item variant="button" class="col-12 mb-4" />
                                    <el-skeleton-item variant="text" class="mb-2 col-4" />
                                    <el-skeleton-item variant="button" class="col-12" />
                                </el-col>
                            </el-row>
                            <div class="row col-12 p-0 m-0 px-1 justify-content-between">
                                <el-skeleton-item variant="button" class="col-12 py-1 col-md-4" />
                                <el-skeleton-item variant="button" class="col-12 py-1 col-md-3" />
                            </div>
                        </template>
                        <template #default>
                            <el-row align="top" :gutter="7" justify="start" class="col-12">
                                <el-col :xs="24" :md="12" :lg="9">
                                    <img :src="'https://api-carga.planejadordetreino.com/images/teams/' + team.logo" class="col-12">
                                </el-col>
                
                                <el-col :xs="24" :md="12" :lg="15">
                                    <el-form-item prop="name" required label="Nome do clube" class="col-12">
                                        <el-input type="text" prefix-icon="Football" name="name" placeholder="Insira o nome do clube" v-model="team.name"/>
                                    </el-form-item>
                                    <el-form-item prop="category" required label="Categoria" class="col-12">
                                        <el-input type="text" prefix-icon="CollectionTag" name="category" placeholder="Insira a categoria" v-model="team.category" />
                                    </el-form-item>
                                </el-col>
        
                                <div class="row col-12 p-0 m-0 px-1 justify-content-between">
                                    <div class="px-0 py-1 col-12 col-md-auto">
                                        <el-button type="success" icon="View" size="large" @click="router.push('/players/' + team.id)" class="col-12 col-md-auto" :loading="loading2">
                                            {{ team.players.length }} jogadores
                                        </el-button>
                                    </div>

                                    <div class="px-0 py-1 col-12 col-md-auto">
                                        <el-button type="primary" :loading="loading2" size="large" @click="send(formRef)" class="col-12 col-md-auto">
                                            Editar
                                        </el-button>
                                    </div>
                                </div>
                            </el-row>
                        </template>
                    </el-skeleton>
                </el-form>
            </el-card>
        </el-col>
    </el-row>
</template>

<script lang="ts">
    import Api from '@/services/api';
import { Player } from '@/services/types';
    import { ElMessage, ElMessageBox, FormInstance, FormRules } from 'element-plus';
    import { defineComponent, onMounted, reactive, ref } from 'vue'
    
    import { useRoute, useRouter } from 'vue-router';

    interface Team {
        id: number
        user_id: number
        name: string
        category: string
        logo: File | null
        players: Array<Player>
        updated_at: string
        created_at: string
    }

    document.title = "Clube"

    export default defineComponent({
        name: 'TeamView',
        setup() {
            
            const blanck = "images/blanck.png"
            const formRef = ref<FormInstance>()
            const user_id = ref<number>(0)
            const loading = ref<boolean>(true)
            const loading2 = ref<boolean>(false)
            const fileRef = ref()
            const route = useRoute()
            const router = useRouter()

            const team = ref<Team>({
                id: 0,
                user_id: user_id.value,
                name: '',
                category: '',
                logo: null,
                players: [],
                created_at: '',
                updated_at: ''
            })

            // get team
            onMounted(async () => {
                loading.value = true
                const res: any = await Api.get("/teams/" + route.params.id)
                if (res.status >= 200 && res.status < 300) {
                   team.value = res.data.team
                } else {
                    ElMessage.error({message: "Ocorre um erro"})
                }
                loading.value = false
            })

            const rules = reactive<FormRules<Team>>({
                name: [{required: true, message: "Por favor digite o nome"}],
                category: [{required: true, message: "Por favor digite a categoria"}],
            })

            const send = async (formEl: FormInstance | undefined) => {
                if (!formEl) { return }
                await formEl.validate(async (valid) => {
                    if (valid) {
                        loading2.value = true
                        
                        const res = await Api.put("/teams/" + route.params.id, {
                            name: team.value.name, 
                            category: team.value.category
                        })
                        if (res.status >= 200 && res.status < 300) {
                            // feito
                            ElMessage.success({message: "Dados do clube atualizados com sucesso"})
                        } else if (res.status == 422) {
                            if (res.data.errors) {
                                const erros = res.data.errors
                                formRef.value?.fields.forEach((val) => {
                                    Object.keys(erros).forEach(field => {
                                        if (val.prop == field) {
                                            val.validateState = "error"
                                            ElMessage({
                                                type: "error",
                                                message: res.data.errors[field][0]
                                            })
                                        }
                                    })
                                })
                            }
                        } else {
                            console.log(res)
                            ElMessageBox.alert("Ocorreu um erro");
                        }
                        loading2.value = false
                    }
                })
            }
            
            return {
                loading,
                loading2,
                team,
                rules,
                fileRef,
                blanck,
                send,
                formRef,
                router
            }
        },
    })
</script>
