<template>
    <el-row :gutter="15">
        <el-col :sm="12" :xs="24" :md="8" :lg="6" class="py-1">
            <el-card class="statistic-card">
                <el-skeleton animated :loading="loading">
                    <template #template>
                        <el-skeleton-item variant="caption"/>
                        <el-skeleton-item variant="h1"/>
                        <el-skeleton-item variant="p"/>
                        <el-skeleton-item />
                    </template>
                    <template #default>
                        <el-statistic :value="stats.teams">
                            <template #title>
                                <div style="display: inline-flex; align-items: center">
                                        Equipas
                                    <el-tooltip
                                        effect="dark"
                                        content="Numero de equipas que esta a gerir neste painel"
                                        placement="top"
                                    >
                                        <el-icon style="margin-left: 4px" :size="12">
                                            <Warning />
                                        </el-icon>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-statistic>
                        <div class="statistic-footer">
                            <div class="footer-item">
                                <span>Cadastradas</span>
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </el-card>
        </el-col>

        <el-col :sm="12" :xs="24" :md="8" :lg="6" class="py-1">
            <el-card class="statistic-card">
                <el-skeleton animated :loading="loading">
                    <template #template>
                        <el-skeleton-item variant="caption"/>
                        <el-skeleton-item variant="h1"/>
                        <el-skeleton-item variant="p"/>
                        <el-skeleton-item />
                    </template>
                    <template #default>
                        <el-statistic :value="stats.players">
                            <template #title>
                                <div style="display: inline-flex; align-items: center">
                                        Jogadores
                                    <el-tooltip
                                        effect="dark"
                                        content="Numero de jogadores que esta a gerir neste painel"
                                        placement="top"
                                    >
                                        <el-icon style="margin-left: 4px" :size="12">
                                            <Warning />
                                        </el-icon>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-statistic>
                        <div class="statistic-footer">
                            <div class="footer-item">
                                <span>nas equipas</span>
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </el-card>
        </el-col> 

        <el-col :sm="12" :xs="24" :md="8" :lg="6" class="py-1">
            <el-card class="statistic-card">
                <el-skeleton animated :loading="loading">
                    <template #template>
                        <el-skeleton-item variant="caption"/>
                        <el-skeleton-item variant="h1"/>
                        <el-skeleton-item variant="p"/>
                        <el-skeleton-item />
                    </template>
                    <template #default>
                        <el-statistic :value="stats.quizzes">
                            <template #title>
                                <div style="display: inline-flex; align-items: center">
                                        Questionários
                                    <el-tooltip
                                        effect="dark"
                                        content="Total e questionarios ja criados"
                                        placement="top"
                                    >
                                        <el-icon style="margin-left: 4px" :size="12">
                                            <Warning />
                                        </el-icon>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-statistic>
                        <div class="statistic-footer">
                            <div class="footer-item">
                                <span>Criados</span>
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </el-card>
        </el-col>

        <el-col :sm="12" :xs="24" :md="8" :lg="6" class="py-1">
            <el-card class="statistic-card">
                <el-skeleton animated :loading="loading">
                    <template #template>
                        <el-skeleton-item variant="caption"/>
                        <el-skeleton-item variant="h1"/>
                        <el-skeleton-item variant="p"/>
                        <el-skeleton-item />
                    </template>
                    <template #default>
                        <el-statistic :value="stats.quizzes_today">
                            <template #title>
                                <div style="display: inline-flex; align-items: center">
                                        De hoje
                                    <el-tooltip
                                        effect="dark"
                                        content="Total de questionarios de hoje"
                                        placement="top"
                                    >
                                        <el-icon style="margin-left: 4px" :size="12">
                                            <Warning />
                                        </el-icon>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-statistic>
                        <div class="statistic-footer">
                            <div class="footer-item">
                                <span>Questionários</span>
                            </div>
                        </div>
                    </template>
                </el-skeleton>

            </el-card>
        </el-col>

    </el-row>
</template>

<script lang="ts">
    import { defineComponent, onMounted, reactive, ref } from 'vue'
    import Api from '@/services/api'
import { ElMessage } from 'element-plus'

    interface Stats {
        quizzes: number | null
        quizzes_today: number | null
        teams: number | null
        players: number | null
    }

    export default defineComponent({
        setup() {
            const loading = ref<boolean>(true)
            const stats = reactive<Stats>({quizzes: null, quizzes_today: null, teams: null, players: null})

            onMounted(async () => {
                loading.value = true
                const res = await Api.get("/home")
                if (res.status >= 200 && res.status < 300) {
                    const data: Stats = res.data
                    stats.players = data.players
                    stats.quizzes = data.quizzes
                    stats.quizzes_today = data.quizzes_today
                    stats.teams = data.teams
                } else {
                    ElMessage.error({
                        message: "OCorreu um erro ao buscar os dados"
                    })
                    console.log(res)
                }
                loading.value = false
            })

            return {
                loading,
                stats
            }
        },
    })
</script>


<style lang="scss" scoped>
    :global(h2#card-usage ~ .example .example-showcase) {
        background-color: var(--el-fill-color) !important;
    }

    .el-statistic {
        --el-statistic-content-font-size: 28px;
    }

    .green {
        color: var(--el-color-success);
    }

    .statistic-card {
        height: 100%;
        padding: 20px;
        border-radius: 4px;
        background-color: var(--el-bg-color-overlay);
    }

    .statistic-footer .footer-item span:last-child {
        display: inline-flex;
        align-items: center;
        margin-left: 4px;
    }
    .bg-dark {
        background-color: #545c64 !important;
    }
</style>
