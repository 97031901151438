<template>
    <el-header class="h2">
        <el-skeleton animated :loading="loading">
            <template #template>
                <el-skeleton-item variant="h1"></el-skeleton-item>
            </template>
            <template #default>Novo questionario</template>
        </el-skeleton>
    </el-header>
    <el-row class="d-flex justify-content-center">
        <el-col :xs="24">
            <el-card shadow="always" class="col-12">
                <el-form
                    label-position="top"
                    label-width="auto"
                    :model="quizz"
                    :rules="rules"
                    ref="formRef"
                    scroll-to-error
                    size="large"
                >
                    <el-skeleton animated :loading="loading" :rows="7">
                        <template #default>
                            <el-row :gutter="7">
                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Clube" prop="team_id" required>
                                        <el-select filterable placeholder="Selecione um clube" v-model="quizz.team_id" @blur="validate" @change="validate">
                                            <template #header>
                                                <h6 class="h6">Meus clubes</h6>
                                            </template>
                                            <el-option v-for="team in teams" :key="team.id" :label="team.name" :value="team.id" class="row">
                                                <div class="col-auto position-relative d-inline-block">
                                                    <el-avatar :alt="team.name" size="small" class="position-absolute d-block" style="top: 0; bottom: 0; left: 0px; margin: auto !important">
                                                        <img :src="'https://api-carga.planejadordetreino.com/images/teams/' + team.logo" />
                                                    </el-avatar>
                                                </div>
                                                <div class="col-auto">
                                                    <span class="label px-2" style="height: 100% !important">{{ team.name }}</span>
                                                </div>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Titulo a apresentar" prop="title" required>
                                        <el-input type="text" placeholder="Um pequeno titulo a ser mostrado" v-model="quizz.title" prefix-icon="View" />
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Data associada" prop="day" required class="col-12">
                                        <el-date-picker format="YYYY/MM/DD" class="col-12" placeholder="inserir data de treino" v-model="quizz.day" :clearable="false" />
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Limites para responder" required prop="to">
                                        <el-date-picker :editable="false" end-placeholder="Até" start-placeholder="Responder de" :clearable="false" type="datetimerange" @change="changeRange" v-model="range" placeholder="Selcionar intervalo de data e hora" />
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Proteger com senha (opcional)" prop="password">
                                        <el-input type="password" clearable placeholder="Senha (opcional)" show-password prefix-icon="Unlock" v-model="quizz.password"/>
                                    </el-form-item>
                                </el-col>

                                <el-col class="py-1" :xs="24" :md="12" :lg="8">
                                    <el-form-item label="Mini descricao" required prop="short_description">
                                        <el-input type="textarea" maxlength="180" :show-word-limit="true" prefix-icon="Promotion" v-model="quizz.short_description" />
                                    </el-form-item>
                                </el-col>
    
                                <el-col class="py-1">
                                    <el-button type="primary" class="col-12 col-sm-auto" @click="send(formRef)" :loading="loading2">
                                        Criar questionário
                                    </el-button>
                                </el-col>
                            </el-row>

                        </template>
                    </el-skeleton>
                </el-form>
            </el-card>
        </el-col>
    </el-row>
</template>

<script lang="ts">
    import Api from '@/services/api'
    import { ElMessage, ElMessageBox, FormInstance, FormItemContext, FormRules } from 'element-plus'
    import { defineComponent, onMounted, reactive, ref } from 'vue'
    import { Team, Quizz } from '@/services/types'
    import { useRouter } from 'vue-router'

    document.title = "Novo questionário"
    export default defineComponent({
        name: 'NewQuizzView',
        setup() {
            
            const router = useRouter()
            const formRef = ref<FormInstance>()
            const loading = ref<boolean>(true)
            const loading2 = ref<boolean>(false) 
            const teams = ref<Array<Team>>([])
            const quizz = reactive<Quizz>({
                team_id: null,
                password: null,
                title: '',
                short_description: '',
                day: null,
                from: null,
                to: null
            })
            const range = ref<Array<Date>>()
            const changeRange = () => {
                if (range.value) {
                    [quizz.from, quizz.to] = range.value
                }
            }

            // fetch teams
            onMounted(async () => {
                loading.value = true
                const res: any = await Api.get("/teams")
                if (res.status >= 200 && res.status < 300) {
                   teams.value = res.data.teams
                } else {
                    ElMessage.error({message: "Ocorre um erro"})
                }
                loading.value = false
            })

            const rules = reactive<FormRules<Quizz>>({
                title: [{required: true, message: "Por favor preencha um título", trigger: "blur"}],
                short_description: [{required: true, message: "Por favor forneça uma descrição", trigger: "blur"}],
                day: [{required: true, message: "Preencha a data do treino para controle", trigger: "blur"}],
                team_id: [{required: true, message: "Selecione um clube", trigger: "blur"}],
                to: [{required: true, message: "Estabeleca os limites para responder", trigger: "blur"}],
                from: [{required: true, message: "Estabeleca os limites para responder", trigger: "blur"}],
            })

            const validate: () => boolean = () => {
                const find = teams.value.filter(({ id }) => id == quizz.team_id)
                const field : FormItemContext | undefined = formRef.value?.fields.filter(val => val.prop == "team_id")[0]
                if (field) {
                    if (find.length < 1) {
                        field.validateState = "error"
                    } else {
                        field.validateState = "success"
                        return true
                    }
                }
                return false
            }

            const add0: (n: number) => string = (n: number) => n < 10 ? `0${n}` : `${n}`

            const send = async (formEl: FormInstance | undefined) => {
                if (!formEl) { return }
                await formEl.validate(async (valid) => {
                    if (valid && validate()) {
                        loading2.value = true

                        const copy: any = {
                            team_id: quizz.team_id,
                            title: quizz.title,
                            short_description: quizz.short_description
                        }
                        if (quizz.password && quizz.password.length > 0) {
                            copy.password = quizz.password
                        }
                        let date: Date | null = quizz.to
                        if (date) {
                            copy.to = `${date?.getFullYear()}-${add0(date.getMonth() + 1)}-${add0(date.getDate())} ${add0(date.getHours())}:${add0(date.getMinutes())}:${add0(date.getSeconds())}`
                        }
                        date = quizz.from
                        if (date) {
                            copy.from = `${date?.getFullYear()}-${add0(date.getMonth() + 1)}-${add0(date.getDate())} ${add0(date.getHours())}:${add0(date.getMinutes())}:${add0(date.getSeconds())}`
                        }
                        date = quizz.day
                        if (date) {
                            copy.day = `${date?.getFullYear()}-${add0(date.getMonth() + 1)}-${add0(date.getDate())}`
                        }

                        const res = await Api.post("/quizz", copy)
                        if (res.status >= 200 && res.status < 300) {
                            ElMessage.success({message: "Questionario publicado com sucesso"})
                            router.push("/quizzs")
                        } else if (res.status == 422) {
                            if (res.data.errors) {
                                const erros = res.data.errors
                                formRef.value?.fields.forEach((val) => {
                                    Object.keys(erros).forEach(field => {
                                        if (val.prop == field) {
                                            val.validateState = "error"
                                            ElMessage({
                                                type: "error",
                                                message: res.data.errors[field][0]
                                            })
                                        }
                                    })
                                })
                            }
                        } else {
                            console.log(res)
                            ElMessageBox.alert("Ocorreu um erro")
                        }
                        loading2.value = false
                    }
                })
            }
            
            return {
                loading,
                loading2,
                teams,
                formRef,
                quizz,
                range,
                rules,
                send,
                validate,
                changeRange
            }
        },
    })
</script>

<style lang="scss" scoped>
</style>
